import { GLSL, Shaders } from "gl-react";

export const SHADERS = Shaders.create({
  SplitColor: {
    frag: GLSL`
      precision mediump float;
      varying vec2 uv;
      uniform sampler2D video;
      uniform float scale;
      const float Y_OFFSET = 0.33;
        
      void main() {
        float scaledY = (uv.y - Y_OFFSET) * scale + Y_OFFSET;
        float halfX = uv.x * 0.5;
        
        vec4 colorMask = texture2D(video, vec2(halfX, scaledY));
        vec4 colorContent = texture2D(video, vec2(halfX + 0.5, scaledY));
        
        gl_FragColor = colorContent * colorMask.r;
      }`,
  },
});
