import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  RootState,
  giftsCacheSelectors,
  loadGiftsBatch,
  mosChatV2Selectors,
} from "src/features/chat/imports/state";
import { GiftId, MosItem, MosUiItem } from "src/features/chat/imports/types";
import { getMosV2ActionForChat } from "src/features/chat/state/actionCreators";
import { MosChatData } from "src/features/chat/types";
import { SupportedMosItemType } from "src/features/mos/types";

const getV2MosDataForChat = (state: RootState) => {
  const mosV2Items = mosChatV2Selectors.getMosChatV2Items(state);

  const items: MosUiItem[] = [];
  const missedGiftsIds: GiftId[] = [];

  mosV2Items.forEach((mosV2Item: MosItem) => {
    const gift = mosV2Item?.giftRichModel?.gift;

    if (gift) {
      items.push({
        content: { gift },
        type: mosV2Item.type,
        typeAbbreviation: mosV2Item.typeAbbreviation,
      });

      return;
    }

    const giftId = mosV2Item?.giftReference?.encryptedGiftId;

    if (giftId) {
      const gift = giftsCacheSelectors.getGiftById(state, giftId);

      if (gift) {
        items.push({
          content: { gift },
          type: mosV2Item.type,
          typeAbbreviation: mosV2Item.typeAbbreviation,
        });
      } else {
        missedGiftsIds.push(giftId);
      }
    }
  });

  return { items, missedGiftsIds };
};

const mosItemsSelector = (state: RootState): MosChatData => {
  const { items, missedGiftsIds } = getV2MosDataForChat(state);

  return {
    items: items.filter(({ type }) => type in SupportedMosItemType),
    missedGiftsIds,
  };
};

export const useMosItemsForChat = ({
  companionId,
}: {
  companionId: string;
}) => {
  const { items, missedGiftsIds } = useSelector(mosItemsSelector, shallowEqual);

  const dispatch = useDispatch();
  const isLoading = useSelector(mosChatV2Selectors.getMosChatV2Loading);

  useEffect(() => {
    dispatch(
      getMosV2ActionForChat({
        companionId,
      })
    );
  }, [dispatch, companionId]);

  useEffect(() => {
    if (missedGiftsIds.length && !isLoading) {
      dispatch(loadGiftsBatch({ giftIds: missedGiftsIds, ignoreCache: true }));
    }
  }, [dispatch, missedGiftsIds, isLoading]);

  return { items, isLoading };
};
