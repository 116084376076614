import {
  AUCTION_TAB_DEFAULT_VALUE,
  AVAILABLE_HOME_PAGES_VERSIONS,
  SECOND,
  STREAM_CHAT_MAX_TEXT_LENGTH_DEFAULT,
  STREAM_MAX_EVENTS_TO_KEEP_DEFAULT,
  homePageMapping,
} from "src/constants";
import {
  AuctionTab,
  CombinedFeedType,
  LiveFeedType,
  PersonalFeedType,
} from "src/enums";
import { getGuestFollowEnabled } from "src/environment";
import { PersonalOffersConfig } from "src/types/personalOffer";
import { RootState } from "state/delegate";
import { withLocalStorageCache } from "state/tree/utils/withLocalsorageCache";
import {
  followingListSelectors,
  serverOwnedConfigSelectors,
} from "./selectors";

const liveFeedTabToSocKey = {
  [LiveFeedType.POPULAR]: "live.popular.update_interval.web",
  [LiveFeedType.NEW]: "live.new.update_interval.web",
  [LiveFeedType.NEARBY]: "live.new.update_interval.web",
  [LiveFeedType.CREATORS]: "live.creators.update_interval.web",
  [LiveFeedType.RECOMMENDED]: "live.recommended.update_interval",
  [LiveFeedType.LANDSCAPE]: "live.landscape.update_interval.web",
  [PersonalFeedType.FOLLOWING]: "live.following.update_interval.web",
  [PersonalFeedType.OLD_FOLLOWING]: "live.old_following.update_interval.web",
  [PersonalFeedType.RECOMMENDED]:
    "live.following_recommendations.update_interval.web",
  [PersonalFeedType.ALL]:
    "live.followings_recommendations_all.update_interval.web",
};

export const LIVE_LEADERBOARD_UPDATE_INTERVAL_KEY =
  "live.leaderboard.update_interval";
export const NFT_AUCTION_CURRENT_ID = "nft.auction.current.id";

const safeExtractMillis = (
  state: RootState,
  key: string,
  defaultSeconds: number
) => {
  const value = serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    key,
    `${defaultSeconds}`
  );
  const number = parseInt(value);

  return (number || defaultSeconds) * 1000;
};

export const parseBooleanEnabledSOC =
  (key: string, defaultValue: string) => (state: RootState) =>
    Boolean(
      JSON.parse(
        serverOwnedConfigSelectors.getConfigParamByKey(state, key, defaultValue)
      )
    );

export const parseArraySOC =
  (key: string, defaultValue = "") =>
  (state: RootState) =>
    serverOwnedConfigSelectors
      .getConfigParamByKey(state, key, defaultValue)
      .split(",")
      .filter(Boolean);

const parseObjectSOC =
  <T>(key: string, defaultValue: string) =>
  (state: RootState): T | null => {
    try {
      return JSON.parse(
        serverOwnedConfigSelectors.getConfigParamByKey(state, key, defaultValue)
      );
    } catch (error) {
      return null;
    }
  };

export const parseNumberSOC =
  (key: string, defaultValue: string) => (state: RootState) =>
    parseInt(
      JSON.parse(
        serverOwnedConfigSelectors.getConfigParamByKey(state, key, defaultValue)
      )
    );

export const getLivePartyInvitesOnPublicBroadcastEnabled =
  parseBooleanEnabledSOC(
    "web.live.party.invites.on.public.broadcast.enabled",
    "0"
  );

export const showLiveButtonInProfile = parseBooleanEnabledSOC(
  "profile.livebutton.enabled",
  "true"
);

export const minimumAgeToBeAbleToEditBirthdate = parseNumberSOC(
  "profile.minAgeToEdit",
  "0"
);

export const getLiveStreamsFeedUpdateIntervalMillis = (
  state: RootState,
  liveFeedType: CombinedFeedType
) => {
  const RECOMMENDED_INTERVAL_SECONDS = 30;
  const DEFAULT_INTERVAL_SECONDS = 60;

  const key = liveFeedTabToSocKey[liveFeedType];
  if (!key) {
    return DEFAULT_INTERVAL_SECONDS * SECOND;
  }

  return safeExtractMillis(
    state,
    key,
    [LiveFeedType.RECOMMENDED, PersonalFeedType.RECOMMENDED].includes(
      liveFeedType
    )
      ? RECOMMENDED_INTERVAL_SECONDS
      : DEFAULT_INTERVAL_SECONDS
  );
};

export const getLeaderboardUpdateIntervalMillis = (state: RootState) =>
  safeExtractMillis(state, LIVE_LEADERBOARD_UPDATE_INTERVAL_KEY, 60);

export const shouldUseNonNativeEmojis = parseBooleanEnabledSOC(
  "webclient.emojis.nonnative",
  "false"
);

export const getLiveChatMaxMessageLength = parseNumberSOC(
  "live.chat.max.length",
  `${STREAM_CHAT_MAX_TEXT_LENGTH_DEFAULT}`
);

export const getMaxHistorySizeInStreamChat = parseNumberSOC(
  "live.chat.max.history",
  `${STREAM_MAX_EVENTS_TO_KEEP_DEFAULT}`
);

export const DEFAULT_LIVE_FEEDS_SORT_VERSION = "4";
export const getLiveSortAlgorithmVersion = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "live.sort.algorithm.version",
    DEFAULT_LIVE_FEEDS_SORT_VERSION
  );

// broadcast
export const getPaidStreamMinPrice = parseNumberSOC(
  "live.paidstream.minprice",
  "99"
);

export const getViralityDiamondsRewardPercentage = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "virality.agent.percent",
    "10"
  );
export const getViralityRewardDuration = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "virality.agent.duration",
    "6"
  );

// happy moments

export const getHappyMomentsGuestFreeLikesEnabled = parseBooleanEnabledSOC(
  "tango.stories.guest.allow_free_likes",
  "1"
);

export const getHappyMomentsViralityEnabled = parseBooleanEnabledSOC(
  "virality.moments.enabled",
  "0"
);

export const getHappyMomentsSettingsEnabled = parseBooleanEnabledSOC(
  "tango.stories.settings.enabled",
  "1"
);

// local follow tcnn
export const getLocalFollowTcnnEnabled = parseBooleanEnabledSOC(
  "live.client.tcnn.follow.enabled",
  "true"
);

export const getLocalFollowTcnnFirstEventMillis = (state: RootState) =>
  safeExtractMillis(state, "live.client.tcnn.follow.first", 6);
export const getLocalFollowTcnnRepeatIntervalMillis = (state: RootState) =>
  safeExtractMillis(state, "live.client.tcnn.follow.repeat", 120);

export const getMoodsLegacyV2Enabled = parseBooleanEnabledSOC(
  "live.moods.v2",
  "1"
);
export const getMoodsV2Enabled = parseBooleanEnabledSOC("mos.v2.enabled", "0");

export const getMoodsV2ForChatEnabled = parseBooleanEnabledSOC(
  "web.chat.mos.enabled",
  "0"
);

export const isLocalSubscriptionsEnabled = parseBooleanEnabledSOC(
  "broadcaster.subscriptions.enabled",
  "1"
);

export const isShowSubscriptionPrice = parseBooleanEnabledSOC(
  "profile.mini.subscriptions.price.enabled",
  "1"
);

export const isMiniGalleryEnabled = parseBooleanEnabledSOC(
  "profile.mini.gallery.enabled",
  "1"
);

export const getGiftsOnScreenList = (state: RootState) =>
  serverOwnedConfigSelectors
    .getConfigParamByKey(state, "live.gifts.on.screen", "")
    .split(",");

export const getBuyCoinsHighlightAmount = parseNumberSOC(
  "gifts.drawer.buy_coins_amount",
  "100"
);

export const getGuestMaxFollowingCount = parseNumberSOC(
  "live.registration.guestmode.following.count",
  "3"
);

export const getGiftFromBroadcasterEnabled = parseBooleanEnabledSOC(
  "gift.from.broadcaster.enabled",
  "0"
);

export const getSalesTaxEnabled = parseBooleanEnabledSOC(
  "sales.tax.enabled",
  "0"
);

export const getMediaGiftGifDuration = parseNumberSOC(
  "media.gift.gif.duration",
  "2"
);

export const guestCanFollowSelector = (state: RootState) =>
  getGuestFollowEnabled() &&
  followingListSelectors.getFollowedUserIds(state).length <
    getGuestMaxFollowingCount(state);

export const getAudioOnlyModeEnabled = parseBooleanEnabledSOC(
  "live.audioonly.enabled",
  "1"
);

export const getMandatoryLoginEnabled = parseBooleanEnabledSOC(
  "login.mandatory.livestream.enabled",
  "0"
);

export const getStreamPreviewMobileEnabled = parseBooleanEnabledSOC(
  "live.video.preview.web.mobile.enabled",
  "0"
);

export const getStartStreamButtonMobileEnabled = parseBooleanEnabledSOC(
  "web.start.stream.button.mobile.enabled",
  "0"
);

export const getGuardianImage = ({
  state,
  size = "medium",
  type,
}: {
  size: string;
  state: RootState;
  type: string;
}) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    `live.message.icon.${size}.${type}`,
    `https://storage.googleapis.com/resources.tango.me/tango-officer-resources/v2/M/${type}.png`
  );

export const getPersonalOffersCashierRefillEnabled = parseBooleanEnabledSOC(
  "web.personalOffers.cashier.refill.enabled",
  "0"
);

export const getOneClickPurchaseDefaultOffer =
  parseObjectSOC<PersonalOffersConfig>(
    "web.one.click.purchase.default.offer",
    ""
  );

export const getOneClickPurchaseEnabled = parseBooleanEnabledSOC(
  "web.one.click.purchase.enabled",
  "0"
);

export const getOneClickV2PurchaseEnabled = parseBooleanEnabledSOC(
  "web.one.click.purchase.v2.enabled",
  "0"
);

export const getCompanyInfo = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(state, "company.info", "");

export const getChatConnectionMinGiftPrice = parseNumberSOC(
  "chats.connection.min.gift.price",
  "8"
);

export const getSafechargeApms = parseArraySOC("safecharge.apms");
export const getAPMsForCreditCardTab = parseArraySOC("paymentTab.creditCard");

export const getIsBannersOnPersonalOffersEnabled = parseBooleanEnabledSOC(
  "web.personalOffers.banners.enabled",
  "0"
);

export const getFollowSuggestionsEnabled = parseBooleanEnabledSOC(
  "follow.suggestions.enabled",
  "0"
);

export const getIsHeaderLogoutEnabled = parseBooleanEnabledSOC(
  "web.header.logout.enabled",
  "0"
);

export const getFollowSuggestionsModerationLevel = parseNumberSOC(
  "follow.suggestions.moderation.level",
  "5"
);

export const getGiftStickerEnabled = parseBooleanEnabledSOC(
  "stream.stickers.gift.enabled",
  "0"
);

export const getImageStickerEnabled = parseBooleanEnabledSOC(
  "stream.stickers.image.enabled",
  "0"
);

export const getGiftsVideoEnabled = parseBooleanEnabledSOC(
  "web.gifts.video.enabled",
  "0"
);

export const getGiftsVideoMovEnabled = parseBooleanEnabledSOC(
  "web.gifts.video.mov.enabled",
  "0"
);

export const getSupportedTranslationsLanguages = parseArraySOC(
  "supported.translations.languages",
  "en,ar,es,fr,hi,ru,tr,vi"
);

export const getDesktopLiveSwipesEnabled = parseBooleanEnabledSOC(
  "web.desktop.live.swipes.enabled",
  "0"
);

export const getIsSkipRefillEnabled = parseBooleanEnabledSOC(
  "web.refill.banner.landingpage.autoopening",
  "0"
);

export const getIsSkipCashierEnabled = parseBooleanEnabledSOC(
  "web.cashier.banner.landingpage.autoopening",
  "0"
);

export const getNavigationFeedToCoinsEnabled = parseBooleanEnabledSOC(
  "navigation.feed.to.coins.enabled",
  "0"
);

export const getOfflineChatTranslationEnabled = parseBooleanEnabledSOC(
  "chat.one.click.translation.enabled",
  "0"
);

export const getOfflineChatAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "chat.alternative.domain.content.support.enabled",
    "0"
  );

export const getStreamsAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "streams.alternative.domain.content.support.enabled",
    "0"
  );

export const getCommonAlternativeContentSupportEnabled = parseBooleanEnabledSOC(
  "common.alternative.domain.content.support.enabled",
  "0"
);

export const getFeedAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "feed.alternative.domain.content.support.enabled",
    "0"
  );

export const getGiftsAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "gifts.alternative.domain.content.support.enabled",
    "0"
  );

export const getPostsAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "posts.alternative.domain.content.support.enabled",
    "0"
  );

export const getProfileAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "profile.alternative.domain.content.support.enabled",
    "0"
  );

export const getOffersAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "offers.alternative.domain.content.support.enabled",
    "0"
  );

export const getAgenciesAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "agencies.alternative.domain.content.support.enabled",
    "0"
  );

export const getLottieAlternativeDomainEnabled = parseBooleanEnabledSOC(
  "lottie.alternative.domain.content.support.enabled",
  "0"
);

export const getLiveChatTranslationEnabled = parseBooleanEnabledSOC(
  "live.translator.enabled",
  "0"
);

export const getRealtimeRecommendationsEnabled = parseBooleanEnabledSOC(
  "web.realtime.recommendations.enabled",
  "0"
);

export const getBannersByTypeModeEnabled = parseBooleanEnabledSOC(
  "web.banners.byType.mode.enabled",
  "0"
);

export const getAlternativeDomainContentSupportEnabled = parseBooleanEnabledSOC(
  "alternative.domain.content.support.enabled",
  "0"
);

export const getIrClickIdEnabled = parseBooleanEnabledSOC(
  "analytics.ir.click.id.enabled",
  "0"
);

export const getVoluumEnabled = parseBooleanEnabledSOC(
  "analytics.voluum.enabled",
  "0"
);

export const getBottomSheetsEnabled = parseBooleanEnabledSOC(
  "web.bottomSheets.enabled",
  "0"
);

export const getMobileKeyboardOverLiveStreamEnabled = parseBooleanEnabledSOC(
  "web.mobile.keyboard.over.live.stream.enabled",
  "0"
);

export const getDatadogAcmeEnabled = parseBooleanEnabledSOC(
  "web.datadog.acme.enabled",
  "0"
);

export const getProfileDeleteAccountEnabled = parseBooleanEnabledSOC(
  "web.profile.delete.account.enabled",
  "0"
);

export const getPostLikeCountEnabled = parseBooleanEnabledSOC(
  "feed.post.like.count.enabled",
  "0"
);

export const getIsGifMessageEnabled = parseBooleanEnabledSOC(
  "chats.gif.message.enabled",
  "0"
);

export const getPremiumMessagesEnabled = parseBooleanEnabledSOC(
  "web.chat.premium.messages.enabled",
  "0"
);

export const getPremiumMessagesSendEnabled = parseBooleanEnabledSOC(
  "web.chat.premium.message.send.enabled",
  "0"
);

export const getChatSendVideoEnabled = parseBooleanEnabledSOC(
  "web.chat.send.video.enabled",
  "0"
);

export const getPremiumMessagesDefaultGiftsList = parseArraySOC(
  "web.chat.premium.message.default.gifts",
  "ssSY6hLfSmA2LtZcdXmNKw,nzZP2cjNuMJK_Unhj7S50Q,xoyWTZnoSPniXnntiYCi_A,Vw0fuExL2Do6mB-c_nbrVg,g6-KokBVuDPq1C59_FveqQ,8oIAXhu3DTN_edF2uI0oNA,MIZbMy-E0hEiGdrHsPK04g,d2S2xYdFtnec3j02HdOcBg,IUcYepOKk9iSDRKiqAkG_g,oS9EOFV6OGYzf16qw9kT-g,HR_Wyca-cxJC9wiRabV_WA,mbVsdl0G2m7eq5nizja5FA,dANaqimAZzLmvQfZLOhd-Q,EgS5iZQiPYvkwy29q_SuWA"
);

export const getSocialGamesEnabled = parseBooleanEnabledSOC(
  "web.socialGames.enabled",
  "0"
);

export const getSocialGamesMenuItemEnabled = parseBooleanEnabledSOC(
  "web.socialGames.menuItem.enabled",
  "0"
);

export const getWelcomePremiumOfferEnabled = parseBooleanEnabledSOC(
  "web.premiumEntrance.welcomeOffer.enabled",
  "0"
);

export const getSocialGamesCardProfitInfoEnabled = parseBooleanEnabledSOC(
  "web.socialGames.card.profitInfo.enabled",
  "0"
);

export const getBannersExternalLinksEnabled = parseBooleanEnabledSOC(
  "web.banners.external.links.enabled",
  "0"
);

export const getPbsExternalLinksEnabled = parseBooleanEnabledSOC(
  "web.pbs.external.links.enabled",
  "0"
);

export const getIsNewSettingsPageEnabled = parseBooleanEnabledSOC(
  "web.new.settings.page.enabled",
  "0"
);

export const getIsNewSettingsPageEmailEnabled = parseBooleanEnabledSOC(
  "web.new.settings.page.email.enabled",
  "0"
);

export const getIsDiamondViewToggleEnabled = parseBooleanEnabledSOC(
  "web.profile.diamondView.toggle.enabled",
  "0"
);

export const getIsNewPhoneSettingsEnabled = parseBooleanEnabledSOC(
  "web.new.settings.page.phone.enabled",
  "0"
);

export const getIsNewPhoneSettingsDeleteEnabled = parseBooleanEnabledSOC(
  "web.new.settings.page.phone.delete.enabled",
  "0"
);

export const getUnathorizedUserLandingPageViewEnabled = parseBooleanEnabledSOC(
  "unauthorized.user.landing.page.view.enabled",
  "0"
);

export const getIsNavigationControlsEnabled = parseBooleanEnabledSOC(
  "web.navigation.new.controls.enabled",
  "0"
);

export const getIsUseGatewayForGifts = parseBooleanEnabledSOC(
  "use.gateway.for.gifts",
  "0"
);

export const getIsBannersForGuestEnabled = parseBooleanEnabledSOC(
  "banners.for.guest.enabled",
  "0"
);

export const getIsLandingPageOpenAsModalEnabled = parseBooleanEnabledSOC(
  "landing.page.open.as.modal.enabled",
  "0"
);

export const getIsStreamCarouselOrderEnabled = parseBooleanEnabledSOC(
  "web.live.carousel.order.enabled",
  "0"
);

export const getWelcomeOfferEnable = parseBooleanEnabledSOC(
  "onboarding.offer.web.show",
  "0"
);

export const getWelcomeOfferDiscountPercentage = parseNumberSOC(
  "onboarding.offer.web.discount.percentage",
  "0"
);

export const getWelcomeOfferCampaignId = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "onboarding.offer.web.campaignId",
    ""
  );

export const getPaymentTrackKeyboardMoveEnabled = parseBooleanEnabledSOC(
  "web.payment.trackKeyboardMove.enabled",
  "0"
);

export const getPaymentTrackKeyboardMoveShowTax = parseBooleanEnabledSOC(
  "web.payment.trackKeyboardMove.showTax",
  "1"
);

export const getAcmeTransactionLoggerEnabled = parseBooleanEnabledSOC(
  "web.acme.transaction.logger",
  "0"
);

export const getIsLandingPageV2Enabled = parseBooleanEnabledSOC(
  "landing.page.v2.enabled",
  "0"
);

export const getIsLandingPageV3Enabled = parseBooleanEnabledSOC(
  "landing.page.v3.enabled",
  "0"
);

export const getImageProxyResizeLiveEnabled = parseBooleanEnabledSOC(
  "image.proxy.resize.live.enabled",
  "0"
);

export const getIsMessageRequestEnabled = parseBooleanEnabledSOC(
  "chats.request.inbox.enabled",
  "0"
);

export const getIsCashierDesignV2Enabled = parseBooleanEnabledSOC(
  "web.cashier.design.v2.enabled",
  "0"
);

export const getIsCashierDesignV2DesktopEnabled = parseBooleanEnabledSOC(
  "web.cashier.design.v2.desktop.enabled",
  "0"
);

export const getIsRefillV2MobileEnabled = parseBooleanEnabledSOC(
  "web.refill.v2.mobile.enabled",
  "0"
);

export const getIsRefillV2DesktopEnabled = parseBooleanEnabledSOC(
  "web.refill.v2.desktop.enabled",
  "0"
);

export const getLoyaltyPersonalOffersEnabled = parseBooleanEnabledSOC(
  "web.loyalty.personal.offers.enabled",
  "0"
);

export const getExternalMessageWithUseDeepLinkEnabled = parseBooleanEnabledSOC(
  "web.external.message.with.use.deep.link.action.enabled",
  "0"
);

export const getLivePartyPipsEnabled = parseBooleanEnabledSOC(
  "web.liveParty.pips.enabled",
  "1"
);

export const getBIHistoryListenEnabled = parseBooleanEnabledSOC(
  "web.tango.bi.history.listen.enabled",
  "0"
);

export const getSnapchatEnabled = parseBooleanEnabledSOC(
  "analytics.snapchat.enabled",
  "0"
);

export const getTwitterMarketingEnabled = parseBooleanEnabledSOC(
  "analytics.twitter.enabled",
  "0"
);

export const getAnalyticsPluginGatewayEnabled = parseBooleanEnabledSOC(
  "analytics.dataMesh.enabled",
  "0"
);

export const getBodyScrollLockEnabled = parseBooleanEnabledSOC(
  "web.body.scroll.lock.enabled",
  "0"
);

export const getSocialGamesV3Enabled = parseBooleanEnabledSOC(
  "web.social.games.v3.enabled",
  "0"
);

export const getSocialGamesV3ViewerEnabled = parseBooleanEnabledSOC(
  "web.social.games.v3.viewer.enabled",
  "0"
);

export const getMosReorderingEnabled = parseBooleanEnabledSOC(
  "mos.reordering.enabled",
  "0"
);

export const getMosReorderingOrder = parseArraySOC(
  "mos.reordering.order",
  "OF,GG,FG,AI,IN,TC,PG,TG,DG"
);

export const getIsRichFragmentCdnEnabled = parseBooleanEnabledSOC(
  "rich.fragment.cdn.enabled",
  "0"
);

export const getRichFragmentCdnURL = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "rich.fragment.cdn.url",
    ""
  );

export const getIsWebviewPersonalOffersEnabled = parseBooleanEnabledSOC(
  "web.webview.personal.offers.enabled",
  "0"
);

export const getIsWebviewOldOffersFallbackEnabled = parseBooleanEnabledSOC(
  "web.webview.old.offers.fallback.enabled",
  "0"
);

export const getRemoveMobileAutoMuteEnabled = parseBooleanEnabledSOC(
  "web.remove.mobile.auto.mute.enabled",
  "0"
);

export const getIsSearchHomePageV2Enabled = parseBooleanEnabledSOC(
  "web.page.home.v2.search.enabled",
  "0"
);

export const getIsHomePageV2ShareStreamEnabled = parseBooleanEnabledSOC(
  "web.page.home.v2.shareStream.enabled",
  "0"
);

export const getHomePageV2AmountOfStreams = parseNumberSOC(
  "web.page.home.v2.liveStreams.amount",
  "10"
);

export const getHomePageV3CategoriesConfig = parseObjectSOC(
  "web.home.page.v3.categories",
  '{"latinAmerica":["AR","BR","CO","CR","CU","DO","EC","SV","GT","HT","HN","JM","MX","NI","PA","PY","PE","PR","SR","UY","VE"],"africa":["DZ","AO","BJ","BW","BF","BI","CV","CM","CF","TD","KM","CG","CD","CI","DJ","EG","GQ","ER","SZ","ET","GA","GM","GH","GN","GW","KE","LS","LR","LY","MG","MW","ML","MR","MU","MA","MZ","NA","NE","NG","RW","ST","SN","SC","SL","SO","ZA","SS","SD","TZ","TG","TN","UG","ZM","ZW"],"asia":["AF","AM","AZ","BH","BD","BT","BN","KH","CN","CY","GE","IN","ID","IR","IQ","IL","JP","JO","KZ","KW","KG","LA","LB","MY","MV","MN","MM","NP","KP","OM","PK","PS","PH","QA","SA","SG","KR","LK","SY","TW","TJ","TH","TL","TR","TM","AE","UZ","VN","YE"],"india":["IN"]}'
);

export const getHomePageDownloadBannerEnabled = parseBooleanEnabledSOC(
  "home.page.v1.download.app.banner",
  "0"
);

export const getAppStoreLinkEnabled = parseBooleanEnabledSOC(
  "web.app.store.link.enabled",
  "0"
);

export const getAppVersionShowEnabled = parseBooleanEnabledSOC(
  "web.app.version.show.enabled",
  "0"
);

export const getDesktopHomePageGetTheAppButtonEnabled = parseBooleanEnabledSOC(
  "web.desktop.home.page.get.the.app.button.enabled",
  "0"
);

export const getGooglePlayApkLink = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "google.play.apk.link",
    "https://resources.tango.me/web/tango-android-8.81.1733431846-arm64.apk"
  );

export const getHomePageDonwloadBannerLink = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "home.page.v1.download.app.banner.link",
    "https://click.tango.me/RCIH/iwkiyxb1"
  );

export const getHomePageAppStoreLink = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "home.page.v1.download.app.qr.code.appstore.link",
    "https://click.tango.me/RCIH/4jqp7bx9"
  );

export const getHomePageGooglePlayLink = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "home.page.v1.download.app.qr.code.googleplay.link",
    "https://click.tango.me/RCIH/4jqp7bx9"
  );

export const getHomePageQRCodeEnabled = parseBooleanEnabledSOC(
  "home.page.v1.download.app.qr.code",
  "0"
);

export const getPipsDraggableEnabled = parseBooleanEnabledSOC(
  "web.pips.draggable.enabled",
  "0"
);

export const getFacebookMarketingEnabled = parseBooleanEnabledSOC(
  "analytics.facebook.enabled",
  "0"
);

export const getBingMarketingEnabled = parseBooleanEnabledSOC(
  "analytics.bing.enabled",
  "0"
);

export const getActionWheelEnabled = parseBooleanEnabledSOC(
  "stream.stickers.action.wheel.enabled",
  "0"
);

export const getGoogleMarketingEnabled = parseBooleanEnabledSOC(
  "analytics.google.ads.enabled",
  "0"
);

export const getTiktokMarketingEnabled = parseBooleanEnabledSOC(
  "analytics.tiktok.ads.enabled",
  "0"
);

export const getIsVouchersPersonalOffersEnabled = parseBooleanEnabledSOC(
  "web.vouchers.personal.offers.enabled",
  "0"
);

export const getIsFinanceMicroFrontendEnabled = parseBooleanEnabledSOC(
  "web.finance.micro.frontend.enabled",
  "0"
);

export const getConsentSettingsEnabled = parseBooleanEnabledSOC(
  "web.new.settings.page.consent.enabled",
  "0"
);

export const getIsBecomeAFanPersonalOffersEnabled = parseBooleanEnabledSOC(
  "web.become.fan.personal.offers.enabled",
  "0"
);

export const getUtmParamsEnabled = parseBooleanEnabledSOC(
  "analytics.utm.params.enabled",
  "0"
);

export const getWebviewApmOffersAnalyticsEnabled = parseBooleanEnabledSOC(
  "webview.apm.offers.analytics.enabled",
  "0"
);

export const getIsSocialGamesLiveSectionEnabled = parseBooleanEnabledSOC(
  "web.social.games.live.section.enabled",
  "0"
);

export const getWebDeclineSelfGiftingEnabled = parseBooleanEnabledSOC(
  "web.decline.self.gifting.enabled",
  "0"
);

export const getCashierVoucherEnabled = parseBooleanEnabledSOC(
  "web.cashier.voucher",
  "0"
);

export const getBroadcastOBSEnabled = parseBooleanEnabledSOC(
  "web.broadcast.obs.enabled",
  "0"
);

export const getIsGoogleOneTapEnabled = parseBooleanEnabledSOC(
  "google.one.tap.enabled",
  "0"
);

export const getAppSecondaryButtonDesktopEnabled = parseBooleanEnabledSOC(
  "home.page.v1.get.app.secondary.button.desktop",
  "0"
);

export const getAppWelcomePageSecondaryButtonDesktopEnabled =
  parseBooleanEnabledSOC("welcome.page.get.app.secondary.button.desktop", "0");

export const getAppSecondaryButtonMobileEnabled = parseBooleanEnabledSOC(
  "home.page.v1.get.app.secondary.button.mobile",
  "1"
);

export const getAppWelcomePageSecondaryButtonMobileEnabled =
  parseBooleanEnabledSOC("welcome.page.get.app.secondary.button.mobile", "1");

export const getAppPrimaryButtonDesktopEnabled = parseBooleanEnabledSOC(
  "home.page.v1.get.app.primary.button.desktop",
  "0"
);

export const getAppWelcomePrimaryButtonDesktopEnabled = parseBooleanEnabledSOC(
  "welcome.page.get.app.primary.button.desktop",
  "0"
);

export const getAppPrimaryButtonMobileEnabled = parseBooleanEnabledSOC(
  "home.page.v1.get.app.primary.button.mobile",
  "1"
);

export const getAppWelcomePrimaryButtonMobileEnabled = parseBooleanEnabledSOC(
  "welcome.page.get.app.primary.button.mobile",
  "1"
);

export const getAppOneLinkURL = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "home.page.v1.get.app.secondary.button.onelink",
    "https://click.tango.me/RCIH/02d99104"
  );

export const getIsPaymentFlowV2Enabled = parseBooleanEnabledSOC(
  "payment.flow.v2.enabled",
  "0"
);

export const getIsUseBasePriceFromOfferCurrentPriceEnabled =
  parseBooleanEnabledSOC("personal.offers.use.base.price", "0");

export const getRTMPGatewayURL = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "obs.rtmp.gateway.url",
    ""
  );

export const getOBSTangoProfileForWinURL = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "obs.tango.profile.win.url",
    "https://resources.tango.me/web/obs-configs/TangoProfileWin.zip"
  );

export const getOBSTangoProfileForMacURL = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "obs.tango.profile.mac.url",
    "https://resources.tango.me/web/obs-configs/TangoProfileMac.zip"
  );

export const getIsOneClickGiftingEnabledByDefault = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "gift.one.click.gifting.default.value",
    "0"
  );

export const getNftAuctionEnabled = parseBooleanEnabledSOC(
  "nft.auction.enabled",
  "0"
);

export const getNftMarketplaceEnabled = parseBooleanEnabledSOC(
  "nft.marketplace.enabled",
  "0"
);

export const getNftAuctionCurrentId = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    NFT_AUCTION_CURRENT_ID,
    ""
  );

export const getNftAuctionLabelById = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "nft.auction.current.label",
    "Tango Cards Auction"
  );

const HOME_PAGE_KEY = "web.page.home";
const DEFAULT_VERSION_KEY = "hash1";
export const getHomePageVersion = (state: RootState) => {
  const [newVersion, newVersionKey] = parseArraySOC(
    HOME_PAGE_KEY,
    `${homePageMapping.v1},${DEFAULT_VERSION_KEY}`
  )(state);

  const result = withLocalStorageCache({
    localStorageKey: HOME_PAGE_KEY,
    value: newVersion,
    versionKey: newVersionKey,
    defaultVersionKey: DEFAULT_VERSION_KEY,
  });

  return AVAILABLE_HOME_PAGES_VERSIONS.includes(result)
    ? result
    : AVAILABLE_HOME_PAGES_VERSIONS[0];
};

export const getIsHomePageEnabled = (state: RootState) =>
  getHomePageVersion(state) === homePageMapping.v1;

export const getIsHomePageV2Enabled = (state: RootState) =>
  getHomePageVersion(state) === homePageMapping.v2;

export const getIsHomePageV3Enabled = (state: RootState) =>
  getHomePageVersion(state) === homePageMapping.v3;

export const getIsHomeSelectLanguageEnabled = parseBooleanEnabledSOC(
  "web.page.home.selectLanguage.enabled",
  "0"
);

export const getDesktopLiveSwipesTutorialDelay = parseNumberSOC(
  "web.desktop.live.swipes.tutorial.delay",
  "7"
);

export const getDesktopLiveSwipesTutorialEnabled = parseBooleanEnabledSOC(
  "web.desktop.live.swipes.tutorial.enabled",
  "0"
);

export const getOFACCountriesList = parseArraySOC(
  "web.ofac.countries.list",
  ""
);

export const getIsRedesignedReferralProgramEnabled = parseBooleanEnabledSOC(
  "web.referral.dashboard.redesign.enabled",
  "0"
);

export const getIsReferralProgramUserTypeSwitchEnabled = parseBooleanEnabledSOC(
  "web.referral.user.type.switch.enabled",
  "0"
);

export const getIsReferralProgramGoalsEnabled = parseBooleanEnabledSOC(
  "web.referral.goals.enabled",
  "0"
);

export const getIsReferralStreamingStatsEnabled = parseBooleanEnabledSOC(
  "web.referral.streaming.stats.enabled",
  "0"
);

export const getLivePartyInvitesEnabled = parseBooleanEnabledSOC(
  "web.live.party.invites.enabled",
  "0"
);

export const getSendLivePartyInvitesEnabled = parseBooleanEnabledSOC(
  "web.live.party.send.invitation.enabled",
  "0"
);

export const getInviteFlowHideBannerAfterSec = (state: RootState) =>
  safeExtractMillis(state, "invite.flow.hide.banner.after.sec", 30);

// TODO move reviewers SOCs to separate file?
export const getIsUserSearchEnabled = parseBooleanEnabledSOC(
  "web.user.search.enabled",
  "0"
);

export const getIsLeaderboardEnabled = parseBooleanEnabledSOC(
  "web.leaderboard.enabled",
  "0"
);

export const getIsFeedEnabled = parseBooleanEnabledSOC("web.feed.enabled", "0");

export const getIsProfileTabsEnabled = parseBooleanEnabledSOC(
  "web.profile.tabs.enabled",
  "0"
);

export const getIsViewStickerEnabled = parseBooleanEnabledSOC(
  "web.stream.sticker.view.enabled",
  "0"
);

export const getIsOfflineChatMediaContentEnabled = parseBooleanEnabledSOC(
  "web.chat.media.content.enabled",
  "0"
);
// TODO move reviewers SOCs to separate file?

export const getOBSPromoBottomSheetDelayMS = parseNumberSOC(
  "obs.promo.bottom.sheet.delay",
  "750"
);

export const getIsFingerprintProEnabled = parseBooleanEnabledSOC(
  "fingerprint.pro.enabled",
  "0"
);

export const getIsEmailBottomSheetEnabled = parseBooleanEnabledSOC(
  "web.email.bottom.sheet.enabled",
  "0"
);

export const getIsEmailMarketingConsentEnabled = parseBooleanEnabledSOC(
  "web.email.marketing.consent.enabled",
  "0"
);

export const getPurchaseMockEnabled = parseBooleanEnabledSOC(
  "purchase.mock.enabled",
  "0"
);

export const isSystemChat = (state: RootState, userId: string) =>
  parseArraySOC(
    "system.user.ids",
    "eU-gJx6V9cbbTlJt8Ry2RQ,wvvL2VhM6p71nlzJiIma9w,GZI5RmQYaYWrBOQS9HJcCg,KY_BlAtecaKAJ_a65y4XVA,CJdpeZQWqhdTYHKhYifZ2A,C2fpPVbpg1a6HfKg2IqEEQ,S-FFl2itsYwWkVHzaFM0yQ,EDL1MKo039cVzN1pSSakmA,nzs5zE3ok3THbaxe25MfxA,j2CzaeaRD-ceNG3VaizbGg,fdvce4cSWTLIAbPHKQrulA,mC5mPUPZh1ZsQP2zhN8s-g,4usZb5pN4vzr69orzq3-Rw,u_CPFatA3kJ-NxQa7_ZIZg,HvbRTL_uEaf5OC9ENiARoA,odJfV7OyW_oyzSlNJEyk0w,BOCzVYddGq5-R4BmvZLkdg,fs0DkJh-PH4qK9o_Ztyx9g,nZf4mxgBc48p2iVOsMxnTQ"
  )(state).includes(userId);

export const getVisitorProfileEnabled = parseBooleanEnabledSOC(
  "visitor.profile.enabled",
  "0"
);

export const getVisitorProfileFeedEnabled = parseBooleanEnabledSOC(
  "visitor.profile.feed.enabled",
  "0"
);

export const getVisitorProfileStreamBadgeEnabled = parseBooleanEnabledSOC(
  "visitor.profile.stream.badge.enabled",
  "0"
);

export const getVisitorProfileVipStatusEnabled = parseBooleanEnabledSOC(
  "visitor.profile.vip.status.enabled",
  "0"
);

export const getVisitorTournamentEnabled = parseBooleanEnabledSOC(
  "visitor.tournament.enabled",
  "0"
);

export const getNftAuctionRemoveLotEnabled = parseBooleanEnabledSOC(
  "nft.auction.remove.lot.enabled",
  "0"
);

export const getNftAuctionStalePeriod = parseNumberSOC(
  "nft.auction.stale_period",
  "10000"
);

export const getNftAuctionLastHourStalePeriod = parseNumberSOC(
  "nft.auction.last_hour.stale_period",
  "2000"
);

export const getNftAuctionCardsPerPage = parseNumberSOC(
  "nft.auction.cards.per.page",
  "25"
);

export const getNftAuctionTags = parseArraySOC(
  "nft.auction.tags",
  "all,my,follow"
);

export const getDownloadAppBannerForAuthorisedUsers = parseBooleanEnabledSOC(
  "download.app.banner.for.authorised.users",
  "0"
);

export const getDownloadAppLink = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "download.app.link",
    "https://help.tango.me/en/articles/10249776-how-to-install-tango-a-step-by-step-guide"
  );

export const getHordemasterHostEnabled = parseBooleanEnabledSOC(
  "hordemaster.host.enabled",
  "0"
);

export const getAlternativeDomainUrlReplacementEnabled = parseBooleanEnabledSOC(
  "alternative.domain.stream.url.replacement.enabled",
  "0"
);

export const getNftAuctionTagLabels = (state: RootState, tabs: AuctionTab[]) =>
  tabs.reduce(
    (acc, tab) => ({
      ...acc,
      [tab]: serverOwnedConfigSelectors.getConfigParamByKey(
        state,
        `nft.auction.tags.label.${tab}`,
        AUCTION_TAB_DEFAULT_VALUE[tab]
      ),
    }),
    {}
  );

export const getNftAuctionAllowedIds = parseArraySOC("nft.auction.allowed.ids");

export const getSiftBeaconKey = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(state, "sift.beaconKey", "");

export const getIsSiftEnabled = parseBooleanEnabledSOC("sift.enabled", "0");

export const getOneLinkTemplate = (state: RootState) =>
  serverOwnedConfigSelectors.getConfigParamByKey(
    state,
    "appsflyer.onelink.template",
    "https://tango.onelink.me/pwm6"
  );

export const getIsOneLinkEnabled = parseBooleanEnabledSOC(
  "appsflyer.onelink.enabled",
  "0"
);

export const getPwaGoogleLoginTrickEnabled = parseBooleanEnabledSOC(
  "web.pwa.google.login.trick.enabled",
  "0"
);

export const getIsHlsAnalyticsEnabled = parseBooleanEnabledSOC(
  "hls.analytics.enabled",
  "0"
);

export const getStreamMaxModerationLevelFullAccess = parseNumberSOC(
  "stream.max.moderation.level.full.access",
  "4"
);

export const getStreamMaxModerationLevel = parseNumberSOC(
  "stream.max.moderation.level",
  "2"
);

export const getIsRedeemsEnabled = parseBooleanEnabledSOC(
  "web.redeems.enabled",
  "0"
);

export const getStreamModerationLevelWhenUndefinedInResponse = parseNumberSOC(
  "stream.moderation.level.when.undefined.in.response",
  "5"
);

export const getIsNsfwLabelEnabled = parseBooleanEnabledSOC(
  "tango.nsfw.label.enabled",
  "0"
);

export const getPreventNullishMultiplierEnabled = parseBooleanEnabledSOC(
  "prevent.nullish.multiplier.enabled",
  "0"
);

export const getGiftsVideoMp4Enabled = parseBooleanEnabledSOC(
  "web.gifts.video.mp4.enabled",
  "0"
);

export const getGiftsSoundMp4Enabled = parseBooleanEnabledSOC(
  "web.gifts.sound.mp4.enabled",
  "0"
);

export const getGiftAnimationsAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "gift.animations.alternative.domain.content.support.enabled",
    "0"
  );

export const getObsStreamAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "obs.stream.alternative.domain.content.support.enabled",
    "0"
  );

export const getRibbonsEnabled = parseBooleanEnabledSOC("ribbons.enabled", "0");

export const getNsfwPipAlternativeDomainContentSupportEnabled =
  parseBooleanEnabledSOC(
    "nsfw.pip.alternative.domain.content.support.enabled",
    "0"
  );

export const getSubscribeButtonEnabled = parseBooleanEnabledSOC(
  "subscribe.button.enabled",
  "0"
);

export const getSubscribeMenuItemEnabled = parseBooleanEnabledSOC(
  "subscribe.menu.item.enabled",
  "0"
);

export const getIsAntMediaServerV2Enabled = parseBooleanEnabledSOC(
  "web.ant.media.server.v2.enabled",
  "0"
);
