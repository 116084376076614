import React, { FC, memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { DEFAULT_GIFT_LINK } from "src/constants";
import { Shader } from "src/features/giftAnimation/exports";
import {
  getGiftAnimationsAlternativeDomainContentSupportEnabled,
  getGiftsSoundMp4Enabled,
  getGiftsVideoMp4Enabled,
} from "src/state/abTests";
import { RootState } from "src/state/delegate";
import { VoidCallback } from "src/types/common";
import { Gift } from "src/types/gift";
import GiftAnimation from "src/ui/animations/GiftAnimation";
import GiftAnimationZip from "src/ui/animations/GiftAnimationZip";
import GiftVideo, { GiftVideoProps } from "src/ui/animations/GiftVideo";
import useGenerateEnabledVideoSources from "src/ui/animations/useGenerateVideoSources";
import { useMakeAlternativeDomainUrl } from "src/ui/hooks/useMakeAlternativeDomainUrl";

export interface GiftAnimationProps {
  components?: {
    GiftVideo?: FC<GiftVideoProps>;
  };
  gift: Gift;
  onComplete?: VoidCallback;
}

const selector = (state: RootState) => ({
  isGiftsVideoMp4Enabled: getGiftsVideoMp4Enabled(state),
  isGiftsSoundMp4Enabled: getGiftsSoundMp4Enabled(state),
});

const GiftAnimationFactory: FC<GiftAnimationProps> = ({
  components = {},
  gift,
  onComplete,
}) => {
  const sources = useGenerateEnabledVideoSources(gift);
  const { isGiftsVideoMp4Enabled, isGiftsSoundMp4Enabled } = useSelector(
    selector,
    shallowEqual
  );

  const {
    lottieAnimationUrl,
    lottieAnimationZipUrl,
    icon,
    assetBundle,
    id,
    mp4AnimationUrl,
  } = gift;

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftAnimationsAlternativeDomainContentSupportEnabled
  );

  const iconUrl = makeAlternativeDomainUrl(icon);
  const defaultGiftLink = makeAlternativeDomainUrl(DEFAULT_GIFT_LINK);

  if (isGiftsVideoMp4Enabled && mp4AnimationUrl) {
    return (
      <Shader
        videoUrl={makeAlternativeDomainUrl(mp4AnimationUrl)}
        isSoundEnabled={isGiftsSoundMp4Enabled}
        onComplete={onComplete}
      />
    );
  }

  if (lottieAnimationUrl) {
    return (
      <GiftAnimation
        url={makeAlternativeDomainUrl(lottieAnimationUrl)}
        onComplete={onComplete}
      />
    );
  }

  if (sources.length) {
    const Component = components.GiftVideo || GiftVideo;

    return <Component sources={sources} onComplete={onComplete} />;
  }

  if (lottieAnimationZipUrl) {
    return (
      <GiftAnimationZip
        url={makeAlternativeDomainUrl(lottieAnimationZipUrl)}
        assetsPath={iconUrl}
        onComplete={onComplete}
        giftId={id}
        defaultGiftLink={defaultGiftLink}
      />
    );
  }

  if (assetBundle) {
    return (
      <GiftAnimationZip
        url={makeAlternativeDomainUrl(assetBundle)}
        assetsPath={iconUrl}
        onComplete={onComplete}
        giftId={id}
        defaultGiftLink={defaultGiftLink}
      />
    );
  }

  if (icon) {
    return (
      <GiftAnimation
        url={defaultGiftLink}
        assetsPath={iconUrl}
        onComplete={onComplete}
      />
    );
  }

  return null;
};

export default memo(GiftAnimationFactory);
