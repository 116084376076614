import { typedDelegateSelectors } from "src/features/broadcastExternal/imports/state";
import { BroadcastMasksState } from "src/features/broadcastMasks/state/slice";

export const localBroadcastMasksSelectors = {
  getConfig: (state: BroadcastMasksState) => state.config,
  getMask: (state: BroadcastMasksState) => state.selectedMask,
  getPlayer: (state: BroadcastMasksState) => state.player,
  getBeauty: (state: BroadcastMasksState) => state.beauty,
  getMakeup: (state: BroadcastMasksState) => state.makeup,
  getMobileViewType: (state: BroadcastMasksState) => state.mobileView,
};

export const broadcastMasksSelectors = typedDelegateSelectors(
  localBroadcastMasksSelectors,
  "broadcastMasks"
);
