import React, {
  MouseEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import MediaBackdrop from "chat/components/common/MediaBackdrop/MediaBackdrop";
import ConversationMuteContext, {
  initialActiveVideo,
} from "chat/components/currentConversation/ConversationMuteContext";
import { useAlternativeDomainContentSupport } from "chat/hooks/useAlternativeDomainContentSupport";
import {
  LoadingIcon,
  PlayIcon,
  SoundOffIcon,
  SoundOffSmallIcon,
  SoundOnIcon,
  SoundOnSmallIcon,
} from "chat/imports/assets";
import { Button, Picture } from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  DeviceType,
  linkToMessageRequestMatch,
} from "chat/imports/constants";
import {
  useBreakpointPrecise,
  useCallbackRef,
  useIntersectionObserver,
  useMakeAlternativeDomainUrl,
  useOnPlaybackStartError,
  useUnmount,
} from "chat/imports/hooks";
import {
  deviceInfoSelectors,
  getAlternativeDomainContentSupportEnabled,
} from "chat/imports/state";
import { Nullable, VoidCallback } from "chat/imports/types";
import { ensureHttps } from "chat/imports/utils";
import { getMediaDimensionsStyle } from "chat/premiumMessage/common/getMediaDimensionsStyle";
import { MessageDuration } from "chat/premiumMessage/ui/components/overlays/MessageDuration";
import { MessageMedia } from "chat/types";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import { MessageConfig } from "./Message";
import styles from "./VideoMessage.scss";
import messagesStyles from "chat/components/currentConversation/Message.scss";

interface EnhancedHTMLVideoElement extends HTMLVideoElement {
  msRequestFullscreen?: VoidCallback;
  webkitEnterFullscreen?: VoidCallback;
  webkitRequestFullscreen?: VoidCallback;
}

interface EnhancedVideoElement extends EnhancedHTMLVideoElement {
  children: HTMLCollectionOf<EnhancedHTMLVideoElement>;
}

interface VideoMessageProps {
  conversationId: string;
  id: number;
  isBlurred?: boolean;
  isShowOnlyPreview?: boolean;
  media: MessageMedia;
  messageConfig: MessageConfig;
}
const VideoMessage: React.FC<VideoMessageProps> = ({
  id,
  isShowOnlyPreview,
  media,
  messageConfig,
  conversationId,
  isBlurred = true,
}) => {
  const { width, height } = media;
  const download_url = useAlternativeDomainContentSupport(media.download_url);
  const thumbnail_url = useAlternativeDomainContentSupport(media.thumbnail_url);

  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const breakpoint = useBreakpointPrecise();
  const { activeVideo, setActiveVideo } = useContext(ConversationMuteContext);
  const isTablet = breakpoint === Breakpoints.TABLET;
  const [isVideoReady, setVideoIsReady] = useState(false);
  const [isVideoLoading, setVideoIsLoading] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const timeoutRef = useRef<number>();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isVisible = useIntersectionObserver(ref, {
    threshold: breakpoint === Breakpoints.DESKTOP ? 0.5 : 0.7,
    rootMargin: "0px 0px 0px 0px",
  });
  const isChatRequest = useRouteMatch(linkToMessageRequestMatch);
  const makeAlternativeDomain = useMakeAlternativeDomainUrl(
    getAlternativeDomainContentSupportEnabled
  );

  const { httpsUrl, httpsThumbnail } = useMemo(
    () => ({
      httpsThumbnail: ensureHttps(makeAlternativeDomain(thumbnail_url)),
      httpsUrl: ensureHttps(makeAlternativeDomain(download_url)),
    }),
    [makeAlternativeDomain, thumbnail_url, download_url]
  );

  const mediaId = `${id}${download_url || ""}`;

  const isMuted = useMemo(
    () => activeVideo.unmuted !== mediaId,
    [activeVideo.unmuted, mediaId]
  );

  const isPlaying = useMemo(
    () => activeVideo.playing === mediaId,
    [activeVideo.playing, mediaId]
  );

  const onPlaybackStartError = useCallbackRef(
    useOnPlaybackStartError(isMuted, setActiveVideo, true)
  ).current;

  const toggleMute = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setActiveVideo({
        ...activeVideo,
        unmuted: isMuted ? mediaId : "",
      });
    },
    [activeVideo, setActiveVideo, mediaId]
  );

  useEffect(() => {
    const handleFullscreenChange = () => {
      const documentElem = document as {
        mozFullScreenElement: Nullable<Element>;
        msFullscreenElement: Nullable<Element>;
        webkitFullscreenElement: Nullable<Element>;
      } & Document;

      const isFs = !!(
        documentElem.fullscreenElement ||
        documentElem.webkitFullscreenElement ||
        documentElem.mozFullScreenElement ||
        documentElem.msFullscreenElement
      );
      setIsFullscreen(isFs);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const isAndroidFullscreen = useMemo(
    () => isFullscreen && deviceType === DeviceType.ANDROID,
    [isFullscreen, deviceType]
  );

  useEffect(() => {
    if (videoRef.current && !isPlaying && !isAndroidFullscreen) {
      videoRef.current?.pause();
      videoRef.current.currentTime = 0;
    }
  }, [videoRef, isPlaying, isAndroidFullscreen]);

  const handlePlaying = useCallback(() => {
    if (!isPlaying) {
      setActiveVideo({
        playing: mediaId,
        unmuted: mediaId,
      });
      setVideoIsReady(true);
      setVideoIsLoading(false);
    }
  }, [isPlaying, mediaId, setVideoIsLoading, setVideoIsReady, setActiveVideo]);

  const handlePlayClick = useCallback(() => {
    setVideoIsLoading(true);

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(() => {
        onPlaybackStartError(videoRef.current);
        setVideoIsReady(false);
        setActiveVideo(initialActiveVideo);
        setVideoIsLoading(false);
      });
    }
  }, [videoRef, onPlaybackStartError, setActiveVideo, setVideoIsReady]);

  const handleFullscreenClick = useCallback(() => {
    const videoTarget = videoRef.current as EnhancedVideoElement;
    if (videoTarget && isPlaying) {
      if (videoTarget.requestFullscreen) {
        videoTarget.requestFullscreen();
      }

      // # for Safari (older versions)
      else if (videoTarget.webkitRequestFullscreen) {
        videoTarget.webkitRequestFullscreen();
      }

      // # for Safari (newer versions)
      else if (videoTarget.webkitEnterFullscreen) {
        videoTarget.webkitEnterFullscreen();
      }

      // # for Safari iPhone (where only the Video tag itself can be fullscreen)
      else if (videoTarget.children[0].webkitEnterFullscreen) {
        videoTarget.children[0].webkitEnterFullscreen();
      }
    }
  }, [videoRef, isPlaying]);

  useEffect(() => {
    if (!isVisible && isVideoReady && isPlaying) {
      setActiveVideo(initialActiveVideo);
      timeoutRef.current = undefined;
      setVideoIsReady(false);
      setVideoIsLoading(false);
    }
  }, [isVisible, isVideoReady, isPlaying, setActiveVideo]);

  useUnmount(() => {
    timeoutRef.current = undefined;
    setActiveVideo(initialActiveVideo);
    setVideoIsReady(false);
    setVideoIsLoading(false);
  });

  const isDesktopLayout = breakpoint === Breakpoints.DESKTOP || isTablet;

  const soundOnIcon = isDesktopLayout ? <SoundOnIcon /> : <SoundOnSmallIcon />;

  const soundOffIcon = isDesktopLayout ? (
    <SoundOffIcon />
  ) : (
    <SoundOffSmallIcon />
  );

  const mediaStyles = getMediaDimensionsStyle(Number(width), Number(height));

  const mediaClasses = isTablet
    ? {}
    : {
        [styles.wideMedia]: Number(width) > Number(height),
        [styles.tallMedia]: Number(width) <= Number(height),
      };

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        styles[breakpoint],
        mediaClasses,
        getGroupMessageClassnames(styles, messageConfig)
      )}
      onClick={handleFullscreenClick}
      style={mediaStyles}
    >
      {(!isPlaying || !isVideoReady) && (!isBlurred || !isChatRequest) && (
        <div className={styles.playContainer} onClick={handlePlayClick}>
          <div className={styles.playIconContainer}>
            {isPlaying && isVideoLoading ? (
              <LoadingIcon className={styles.loadingIcon} />
            ) : (
              <PlayIcon className={styles.playIcon} />
            )}
          </div>
        </div>
      )}
      <MediaBackdrop
        conversationId={conversationId}
        messageId={id}
        messageConfig={messageConfig}
        isBlurred={isBlurred}
        onClick={handlePlayClick}
      />
      <Picture
        className={classnames(
          styles.thumbnail,
          messagesStyles.videoMessageThumbnail,
          getGroupMessageClassnames(styles, messageConfig),
          {
            [messagesStyles.firstInGroup]: messageConfig.isFirstInGroup,
            ...mediaClasses,
          }
        )}
        src={httpsThumbnail}
        style={mediaStyles}
      />
      <>
        <video
          ref={videoRef}
          disablePictureInPicture
          controlsList="nodownload"
          preload="metadata"
          poster={httpsThumbnail}
          controls={!isShowOnlyPreview}
          loop
          playsInline
          muted={isMuted}
          onPlaying={handlePlaying}
          src={httpsUrl}
          style={mediaStyles}
          className={classnames(
            styles.video,
            messagesStyles.videoMessage,
            getGroupMessageClassnames(styles, messageConfig),
            {
              [styles.visible]: isPlaying && isVideoReady,
              [messagesStyles.firstInGroup]: messageConfig.isFirstInGroup,
              ...mediaClasses,
            }
          )}
        />
      </>
      {isPlaying && isVideoReady && (
        <Button
          className={styles.soundBtn}
          size={ButtonSize.CIRCLE_SMALL_32}
          variant={ButtonVariant.SYSTEM_DARK}
          onClick={toggleMute}
        >
          {isMuted ? soundOffIcon : soundOnIcon}
        </Button>
      )}
      <MessageDuration duration={media.duration ?? 0} />
    </div>
  );
};

export default memo(VideoMessage);
