import { createAsyncThunk } from "@reduxjs/toolkit";

import { loadBroadcastMasksConfigRequest } from "src/features/broadcastMasks/api/broadcastMasksApi";
import { BroadcastMasksConfig } from "src/features/broadcastMasks/common/types";
import { RootState } from "src/features/broadcastMasks/imports/state";
import { isApiError } from "src/features/broadcastMasks/imports/utils";

export const loadBroadcastMasksConfig = createAsyncThunk<
  BroadcastMasksConfig,
  void,
  { rejectValue: string; state: RootState }
>("lwc/broadcastMasks/loadBroadcastMasksConfig", async (_, api) => {
  try {
    return await loadBroadcastMasksConfigRequest();
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
