import React, { UIEvent, useState } from "react";
import classnames from "classnames";
import { MosGift } from "src/features/chat/components/Mos/MosGift";
import { MosUiItem } from "src/features/chat/imports/types";
import styles from "src/features/chat/components/Mos/MosContainer.scss";

interface MosListProps {
  carouselRef: React.RefObject<HTMLDivElement>;
  mosItems: MosUiItem[];
  setIsNextBtnDisabled: (disabled: boolean) => void;
  setIsPrevBtnDisabled: (disabled: boolean) => void;
}

enum MaskSide {
  BOTH = "both",
  LEFT = "left",
  RIGHT = "right",
}

export const MosList = ({
  mosItems,
  carouselRef,
  setIsPrevBtnDisabled,
  setIsNextBtnDisabled,
}: MosListProps) => {
  const [maskStyles, setMaskStyles] = useState(MaskSide.RIGHT);

  const handleCarouselScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const scrollLeftMax =
      target.scrollWidth - target.getBoundingClientRect().width;

    const delta = 1;

    setIsPrevBtnDisabled(target.scrollLeft <= delta);
    setIsNextBtnDisabled(scrollLeftMax - target.scrollLeft <= delta);

    if (target.scrollLeft <= delta) {
      setMaskStyles(MaskSide.RIGHT);
    } else if (scrollLeftMax - target.scrollLeft <= delta) {
      setMaskStyles(MaskSide.LEFT);
    } else {
      setMaskStyles(MaskSide.BOTH);
    }
  };

  return (
    <div
      ref={carouselRef}
      className={classnames(styles.carousel, {
        [styles.maskLeftSide]: maskStyles === MaskSide.LEFT,
        [styles.maskRightSide]: maskStyles === MaskSide.RIGHT,
        [styles.maskBothSides]: maskStyles === MaskSide.BOTH,
      })}
      onScroll={handleCarouselScroll}
      data-testid="chat-mos-list"
    >
      {mosItems.map(({ content: { gift } }) => (
        <MosGift key={gift.id} gift={gift} />
      ))}
    </div>
  );
};
