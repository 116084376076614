import { HTTP_CODE_ERROR, HTTP_CODE_SUCCESS } from "enums/httpCodes";

export enum ReferredUsersResponseCode {
  SERVER_ERROR = HTTP_CODE_ERROR,
  SUCCESS = HTTP_CODE_SUCCESS,
}

export enum ReferredUsersOrder {
  ASC = "asc",
  DESC = "desc",
}
export enum ReferredUsersSortBy {
  EARNED_POINTS = "earnedPoints",
  FIRST_NAME = "firstName",
  POINTS = "points",
  REDEEM_POINTS = "redeemPoints",
}

export enum UserType {
  ALL = "all",
  NEW = "new",
}

export enum TimeRange {
  CURRENT_MONTH = "current_month",
  LAST_MONTH = "previous_month",
  LIFETIME = "lifetime",
  RECENT_7_DAYS = "recent_7_days",
  RECENT_30_DAYS = "recent_30_days",
  TODAY = "today",
  YESTERDAY = "yesterday",
}

export enum Currency {
  DIAMONDS = "DIAMONDS",
  USD = "USD",
}

export enum ReferralType {
  CREATORS = "creators",
  SUPPORTERS = "supporters",
}

export enum AgencyLevel {
  BRONZE = "BRONZE",
  ELITE = "ELITE",
  GOLD = "GOLD",
  ROOKIE = "ROOKIE",
  ROYAL = "ROYAL",
  SILVER = "SILVER",
}

export enum PromotionMessageVariant {
  ACT_FAST = "ACT_FAST",
  HUNDRED_PERCENT = "HUNDRED_PERCENT",
  HURRY_UP = "HURRY_UP",
  ON_FIRE = "ON_FIRE",
  WELL_DONE = "WELL_DONE",
}
