import emptyFunction from "fbjs/lib/emptyFunction";
import { NEW_SIGN_UP_PROMOTION_TYPE_OTHER } from "enums/newSignUpPromotionType";
import { BottomScreenType, LoginModalTitleType } from "src/enums";
/* eslint-disable no-restricted-imports */
import {
  BOTTOM_SCREEN_ANIMATION,
  HIDE_BOTTOM_SCREEN,
  SHOW_BOTTOM_SCREEN,
} from "state/actionTypes";
/* eslint-enable no-restricted-imports */

export const showBottomScreen = ({
  screenType,
  screenData,
  onDismiss,
  onOutsideClickDismiss,
  redirectOnDismiss,
}) => ({
  type: SHOW_BOTTOM_SCREEN,
  payload: {
    screenType,
    screenData,
    onDismiss,
    onOutsideClickDismiss,
    redirectOnDismiss,
  },
});

export const hideBottomScreen = () => ({
  type: HIDE_BOTTOM_SCREEN,
});

export const setAnimation = (animation) => ({
  type: BOTTOM_SCREEN_ANIMATION,
  payload: { animation },
});

export const openTopGiftersInStreamBottomScreen = () =>
  showBottomScreen({ screenType: BottomScreenType.TOP_GIFTERS_IN_STREAM });

export const openConfirmationBottomScreen = ({
  title,
  confirmText,
  confirm,
  dismissText,
  dismiss = emptyFunction,
  className,
  body,
  swapActionButtons,
  isConfirmButtonHighlighted,
  basicProfile,
  isShaderTheme,
  ...rest
}) =>
  showBottomScreen({
    screenType: isShaderTheme
      ? BottomScreenType.CONFIRMATION_BOTTOM_SCREEN_SHADER
      : BottomScreenType.CONFIRMATION_BOTTOM_SCREEN,
    screenData: {
      title,
      confirmText,
      confirm,
      dismissText,
      dismiss,
      className,
      body,
      swapActionButtons,
      isConfirmButtonHighlighted,
      basicProfile,
      isShaderTheme,
      ...rest,
    },
  });

export const openGiftsDrawerInStreamBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.GIFTS_DRAWER_BOTTOM_SCREEN,
    ...props,
  });

export const openSendMediaBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.SEND_MEDIA_TO_CHAT,
    ...props,
  });

export const openCheckoutBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.CHECKOUT_BOTTOM_SCREEN,
    ...props,
  });

export const openRefillDrawerBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.REFILL_DRAWER_BOTTOM_SCREEN,
    ...props,
  });

export const openRefillV2DrawerBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.REFILL_V2_DRAWER_BOTTOM_SCREEN,
    ...props,
  });

export const openNsfwContentBottomScreen = ({
  redirectLink,
  shouldRedirectOnClose = false,
  onOutsideClickDismiss = () => {},
  shouldUseCallback = false,
  callback = () => {},
  onBeforeConfirm = () => {},
}) =>
  showBottomScreen({
    screenType: BottomScreenType.OPEN_NSFW_CONTENT_BOTTOM_SCREEN,
    screenData: {
      redirectLink,
      shouldRedirectOnClose,
      onOutsideClickDismiss,
      shouldUseCallback,
      callback,
      onBeforeConfirm,
    },
  });

export const openSignUpBottomScreen = ({
  promotionType = NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
  dark = false,
  redirectOnDismiss,
  registrationSource,
  onDismiss,
  onOutsideClickDismiss,
  isShowPhoneNumberLoginFlow = false,
  title = LoginModalTitleType.REGULAR,
  screenType,
  onLoginSuccess,
} = {}) =>
  showBottomScreen({
    screenType:
      screenType ||
      (dark
        ? BottomScreenType.SIGN_UP_BOTTOM_SCREEN_DARK
        : BottomScreenType.SIGN_UP_BOTTOM_SCREEN_WHITE),
    screenData: {
      promotionType,
      dark,
      isShowPhoneNumberLoginFlow,
      registrationSource,
      title,
      onLoginSuccess,
    },
    redirectOnDismiss,
    onOutsideClickDismiss,
    onDismiss,
  });

/**
 * @deprecated use openDeleteNftCardBottomScreen
 */
export const openRemoveNftCardModalBottomScreen = ({
  confirm = false,
  accountId,
  lotId,
}) =>
  showBottomScreen({
    screenType: BottomScreenType.BOTTOM_NFT_REMOVE_CARD_MODAL,
    screenData: { confirm, accountId, lotId },
  });

export const openDeleteNftCardBottomScreen = ({ accountId, lotId }) =>
  showBottomScreen({
    screenType: BottomScreenType.NFT_DELETE_CARD_BOTTOM_SCREEN,
    screenData: {
      accountId,
      lotId,
    },
  });

export const openLandingPageBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.LANDING_PAGE_BOTTOM_SCREEN,
    ...props,
  });

export const openLandingV3BottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.LANDING_BOTTOM_SCREEN,
    ...props,
  });

export const openBottomSheetBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.BOTTOM_SHEET,
    ...props,
  });

export const openDeleteAccountBottomScreen = () =>
  showBottomScreen({
    screenType: BottomScreenType.DELETE_ACCOUNT_MODAL,
    screenData: {},
  });

export const openSelectCountryBottomScreen = () =>
  showBottomScreen({
    screenType: BottomScreenType.SELECT_COUNTRY_BOTTOM_SCREEN,
    screenData: {},
  });

export const openDeletePhoneNumberBottomScreen = () =>
  showBottomScreen({
    screenType: BottomScreenType.DELETE_PHONE_NUMBER_BOTTOM_SCREEN,
    screenData: {},
  });

export const openEditPhoneNumberBottomScreen = ({ onOutsideClickDismiss }) =>
  showBottomScreen({
    screenType: BottomScreenType.EDIT_PHONE_NUMBER_BOTTOM_SCREEN,
    screenData: { onOutsideClickDismiss },
  });

export const openDeleteEmailBottomScreen = ({ email }) =>
  showBottomScreen({
    screenType: BottomScreenType.PROFILE_SETTINGS_DELETE_EMAIL,
    screenData: { email },
  });

export const openEditEmailBottomScreen = ({ email }) =>
  showBottomScreen({
    screenType: BottomScreenType.PROFILE_SETTINGS_EMAIL,
    screenData: { email },
  });

export const openOptionsBottomScreen = ({ options }) =>
  showBottomScreen({
    screenType: BottomScreenType.PROFILE_SETTINGS_OPTIONS_BOTTOM_SCREEN,
    screenData: { options },
  });

export const openSocialGamesBottomScreen = () =>
  showBottomScreen({
    screenType: BottomScreenType.SOCIAL_GAMES_BOTTOM_SCREEN,
  });

export const openCashierV2DrawerBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.CASHIER_V2_DRAWER_BOTTOM_SCREEN,
    ...props,
  });

export const openBroadcastFiltersLobby = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.BROADCAST_FILTERS_SCREEN,
    ...props,
  });

export const openBroadcastResetSettingsScreen = ({ onReset, ...props }) =>
  showBottomScreen({
    screenType: BottomScreenType.BROADCAST_RESET_SETTINGS_SCREEN,
    screenData: { onReset },
    ...props,
  });

export const openAgencyFiltersBottomScreen = (props) =>
  showBottomScreen({
    screenType: BottomScreenType.REFERRAL_FILTERS_SETTINGS,
    ...props,
  });

export const openBroadcastLobbySettingsBottomScreen = ({
  screenData,
  ...props
}) =>
  showBottomScreen({
    screenType: BottomScreenType.BROADCAST_LOBBY_SETTINGS_BOTTOM_SCREEN,
    screenData,
    ...props,
  });
