import {
  GetUserHashedInfoResponse,
  initFlirtyAudience,
} from "src/features/marketing/api/marketing";
import {
  AdditionalDataPoints,
  MicrosoftAdsMethods,
  SearchParams,
  SnapchatEventFields,
  SnapchatMethods,
  cleanLocalStorageByKeys,
  getSnapchatPixelId,
} from "src/features/marketing/imports";

export const initializeMarketingPixel = (resp: GetUserHashedInfoResponse) => {
  window.fbq?.("init", "933804854808402", {
    em: resp?.email,
    ph: resp?.phoneNumber,
  });
  window.fbq?.("track", "PageView");

  window.uetq?.push(MicrosoftAdsMethods.SET, {
    pid: {
      em: resp?.hashedEmail,
      ph: resp?.hashedPhoneNumber,
    },
  });

  const snapchatClickId = localStorage.getItem(SearchParams.SNAPCHAT);

  window.snaptr?.(SnapchatMethods.INIT, getSnapchatPixelId(), {
    [SnapchatEventFields.HASHED_EMAIL]: resp?.hashedEmail,
    [SnapchatEventFields.HASHED_PHONE]: resp?.hashedPhoneNumber,
    [SnapchatEventFields.USER_EMAIL]: resp?.email,
    [SnapchatEventFields.CLICK_ID]: snapchatClickId,
  });
};

export const handleInitFlirtyAudience = () => {
  if (!localStorage.getItem(AdditionalDataPoints.MARKETING_FL)) {
    return;
  }

  initFlirtyAudience().finally(() => {
    cleanLocalStorageByKeys([AdditionalDataPoints.MARKETING_FL]);
  });
};
