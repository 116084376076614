import { ApplicationThunk } from "src/features/broadcastMasks/imports/types";
import { loadBroadcastMasksConfig } from "src/features/broadcastMasks/state/asyncAction";
import {
  loadConfigComplete,
  loadConfigFailed,
  loadConfigStart,
} from "src/features/broadcastMasks/state/slice";

export const loadBroadcastMasksConfigFlow =
  (): ApplicationThunk => async (dispatch) => {
    dispatch(loadConfigStart());

    return dispatch(loadBroadcastMasksConfig())
      .unwrap()
      .then((response) => {
        dispatch(loadConfigComplete(response));
      })
      .catch((error) => {
        dispatch(loadConfigFailed(error));
      });
  };
