import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import classnames from "classnames";
import ChatLayout from "chat/components/ChatLayout/ChatLayout";
import ChatNotificationsMuter from "chat/components/ChatNotificationsMuter/ChatNotificationsMuter";
import EmptyStateWithError from "chat/components/common/EmptyStateWithError";
import { ChatAvatarColorContextProvider } from "chat/context/ChatAvatarColorContext";
import { Direction } from "chat/enums";
import { clearGiftsToPlay } from "chat/giftAnimation/export";
import useLoadAndReadConversation from "chat/hooks/useLoadAndReadConversation";
import usePollConversations from "chat/hooks/usePollConversations";
import { GetTheAppSource, RegistrationSource } from "chat/imports/analytics";
import {
  FormattedMessageTitle,
  JoinTangoBanner,
  LoginGate,
  NsfwContentHidden,
} from "chat/imports/components";
import {
  Breakpoints,
  DisplayAreas,
  defaultRoute,
  linkToChat,
  linkToMessageRequest,
} from "chat/imports/constants";
import { getIsChatDisabled } from "chat/imports/environment";
import {
  useBottomSheetShow,
  useBreakpoint,
  useBreakpointPrecise,
  useRefreshGiftsDrawer,
  useUnmount,
} from "chat/imports/hooks";
import {
  RootState,
  batchLoadProfiles,
  getIsMessageRequestEnabled,
} from "chat/imports/state";
import { sharedMessages } from "chat/imports/utils";
import { messageRequestSelectors } from "chat/messageRequest/exports/state";
import { settingsSelectors } from "chat/messageRequest/imports/state";
import {
  fetchConversations,
  setCurrentConversationId,
} from "chat/state/actionCreators";
import chatSelectors from "chat/state/selectors";
import { ConversationState } from "chat/types";
import styles from "./Chat.scss";

const selector = (state: RootState) => ({
  conversations: chatSelectors.getConversations(state),
  meta: chatSelectors.meta(state),
  isMessageRequestEnabled: getIsMessageRequestEnabled(state),
  messagesRequests: messageRequestSelectors.getConversations(state),
  isNsfwEnabled: settingsSelectors.isNsfwEnabled(state),
  isSettingsLoaded: settingsSelectors.isSettingsLoaded(state),
});

const ChatContent = memo(() => {
  const { id } = useParams<{ id?: string }>();
  const isMessageRequestRoute = useRouteMatch(linkToMessageRequest);
  const breakpoint = useBreakpointPrecise();
  const history = useHistory();

  const {
    conversations,
    meta: { error },
    isMessageRequestEnabled,
    messagesRequests,
    isNsfwEnabled,
    isSettingsLoaded,
  } = useSelector(selector, shallowEqual);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const [isRequestsVisible, setIsRequestsVisible] = useState(
    isMessageRequestRoute && !isMessageRequestEnabled
      ? false
      : !!isMessageRequestRoute
  );

  useEffect(() => {
    if (!isDesktop) {
      setIsRequestsVisible(!!isMessageRequestRoute);
    }
  }, [isDesktop, isMessageRequestRoute]);

  useEffect(() => {
    if (isMessageRequestRoute && !isMessageRequestEnabled) {
      history.push(linkToChat);
    }
  }, [isMessageRequestRoute, isMessageRequestEnabled, history]);

  const currentConversation =
    id && conversations.find(({ conversation_id }) => conversation_id === id);

  const dispatch = useDispatch();
  const participantsIds = useMemo(
    () => [
      ...Object.values(conversations).reduce((acc, next) => {
        if (next.account_info) {
          acc.add(next.account_info.account_id);

          return acc;
        }

        if (next.group_info?.members) {
          next.group_info.members
            .slice(0, 3)
            .forEach((member) => acc.add(member.account_info.account_id));

          return acc;
        }

        return acc;
      }, new Set()),
    ],
    [conversations]
  );

  const reload = useCallback(() => {
    dispatch(
      fetchConversations({
        direction: Direction.FORWARD,
        last_update_request_timestamp: 0,
        include_group_members: true,
        include_messages: true,
        include_group_info: true,
        include_account_info: true,
        limit_messages_per_conversation: 1,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      batchLoadProfiles({ ids: participantsIds, loadOnlyIfMissing: true })
    );
  }, [participantsIds, dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(setCurrentConversationId(id || null));
      dispatch(clearGiftsToPlay());
    });
  }, [dispatch, id]);

  useUnmount(() => {
    batch(() => {
      dispatch(setCurrentConversationId(null));
      dispatch(clearGiftsToPlay());
    });
  });

  useLoadAndReadConversation(id);
  usePollConversations();

  const isIncomingMessageRequest =
    typeof currentConversation !== "string" &&
    currentConversation?.state === ConversationState.CHAT_REQUEST &&
    currentConversation.hidden;

  if (!isSettingsLoaded) {
    return null;
  }

  if (error) {
    return <EmptyStateWithError onClick={reload} />;
  }

  if (isMessageRequestRoute && isMessageRequestEnabled && !isNsfwEnabled) {
    return <NsfwContentHidden redirectLink={linkToMessageRequest} />;
  }

  if (isIncomingMessageRequest && isMessageRequestEnabled && !isNsfwEnabled) {
    return <NsfwContentHidden redirectLink={`${linkToMessageRequest}/${id}`} />;
  }

  if (isRequestsVisible) {
    return (
      <ChatLayout
        conversations={messagesRequests}
        currentConversationId={id}
        isRequestsVisible={isRequestsVisible}
        setIsRequestsVisible={setIsRequestsVisible}
        currentConversation={currentConversation}
        isMessageRequestEnabled={isMessageRequestEnabled}
      />
    );
  }

  return (
    <ChatLayout
      conversations={conversations.filter(
        (conversation) =>
          conversation.state !== ConversationState.CHAT_REQUEST ||
          !conversation.hidden
      )}
      currentConversationId={id}
      isRequestsVisible={isRequestsVisible}
      setIsRequestsVisible={setIsRequestsVisible}
      currentConversation={currentConversation}
      isMessageRequestEnabled={isMessageRequestEnabled}
    />
  );
});

ChatContent.displayName = "ChatContent";

const Chat: React.FC<RouteComponentProps> = () => {
  const breakpoint = useBreakpoint();

  useBottomSheetShow(DisplayAreas.OFFLINE_CHAT);

  useRefreshGiftsDrawer();

  if (getIsChatDisabled()) {
    return <Redirect to={defaultRoute} />;
  }

  return (
    <div className={classnames(styles.root, styles[breakpoint])}>
      <FormattedMessageTitle
        id="page.seo.title.chat"
        defaultMessage="Tango Live 1x1 Video Chat with broadcasters"
        isOverrideTitle
      />
      <LoginGate
        fallbackContent={
          <JoinTangoBanner
            className={styles.joinTango}
            title={sharedMessages.signUpToChat}
            screenSource={GetTheAppSource.JOIN_BUTTON}
            registrationSource={RegistrationSource.CHAT_TAB}
          />
        }
      >
        <ChatAvatarColorContextProvider>
          <ChatContent />
        </ChatAvatarColorContextProvider>
        <ChatNotificationsMuter />
      </LoginGate>
    </div>
  );
};

export default Chat;
