import React, { FC, memo, useCallback, useMemo, useRef } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import classnames from "classnames";
import { MultiBroadcastAnswerResult } from "@analytics/enums";
import {
  emitMultiStreamInvitationAnswer,
  emitMultiStreamInvitationDisplayed,
} from "@analytics/multiStreamInvitationEmitters";
import { PLUS } from "src/constants";
import { DataDogErrorName, ToastType } from "src/enums";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/state/selectors";
import { RootState } from "src/state/delegate";
import { ToastInviteProps } from "src/types/toast";
import {
  getAlternativeDomainContentSupportEnabled,
  getInviteFlowHideBannerAfterSec,
} from "state/abTests";
import { broadcastSelectors, navigationSelectors } from "state/selectors";
import { RoundedNumber } from "ui/common/Formatted";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useLivePartyInvites from "ui/hooks/useLivePartyInvites";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import { useToast } from "ui/hooks/useToast";
import useIsOnBroadcast from "ui/navigation/useIsOnBroadcast";
import useIsOnStream from "ui/navigation/useIsOnStream";
import Player from "ui/player";
import ToastWrapper from "ui/toast/ToastWrapper/ToastWrapper";
import { useMount } from "utils/miniReactUse";
import { ReactComponent as IconCross } from "img/ic_close.svg";
import { ReactComponent as DiamondIcon } from "img/ic_diamond_12.svg";
import { ReactComponent as EyeIcon } from "img/ic_eye_12.svg";
import { ReactComponent as IconChecked } from "img/user_menu_icons/check-mark_32.svg";
import styles from "./Toast.scss";

const messages = defineMessages({
  battleRequest: {
    id: "multi_stream_invite_battle",
    defaultMessage: "Battle Request",
  },
  bonus: {
    id: "offer.bonus",
    defaultMessage: "Bonus",
  },
  regularRequest: {
    id: "multi_stream_invite_regular",
    defaultMessage: "Join Request",
  },
});

const ToastInvite: FC<ToastInviteProps> = ({
  id: toastId,
  multiBroadcastInvite,
  broadcastId,
  myAccountId,
  type,
}) => {
  const videoRef = useRef(null);
  const breakpoint = useBreakpointPrecise();
  const isOnStream = useIsOnStream();
  const isOnBroadcast = useIsOnBroadcast();
  const isShader = isOnStream || isOnBroadcast;
  const makeAlternativeDomain = useMakeAlternativeDomainUrl(
    getAlternativeDomainContentSupportEnabled
  );

  const { remove, removeByType } = useToast();
  const { acceptInvite, rejectInvite } = useLivePartyInvites({
    accountId: myAccountId,
    invite: multiBroadcastInvite,
    streamId: broadcastId,
  });

  const {
    hostPreviewUrl,
    hostFullName,
    hostTotalPoint,
    hostViewerCount,
    hostLPBonus,
    hostLPBonusV2,
    isBattleRequest,
  } = multiBroadcastInvite;

  const inviteMessage =
    messages[isBattleRequest ? "battleRequest" : "regularRequest"];

  const toastLifeTime = useSelector(getInviteFlowHideBannerAfterSec);

  const hasBonus = hostLPBonus > 0 || hostLPBonusV2 > 0;

  const {
    broadcastExternalStatus,
    broadcastKind,
    broadcastStatus,
    currentRoute,
  } = useSelector(
    (state: RootState) => ({
      broadcastExternalStatus:
        broadcastExternalSelectors.getBroadcastExternalStatus(state),
      broadcastKind: broadcastSelectors.broadcastKind(state),
      broadcastStatus: broadcastSelectors.broadcastStatus(state),
      currentRoute: navigationSelectors.getCurrentRoute(state),
    }),
    shallowEqual
  );

  const appState = useMemo(
    () => ({
      broadcastExternalStatus,
      broadcastId,
      broadcastKind,
      broadcastStatus,
      currentRoute,
    }),
    [
      broadcastExternalStatus,
      broadcastId,
      broadcastKind,
      broadcastStatus,
      currentRoute,
    ]
  );

  const buttonVariants = useMemo(
    () =>
      isShader
        ? {
            primary: ButtonVariant.PRIMARY_ACCENT,
            secondary: ButtonVariant.SECONDARY_ON_SHADER,
          }
        : {
            primary: ButtonVariant.PRIMARY,
            secondary: ButtonVariant.SECONDARY,
          },
    [isShader]
  );

  const handleAcceptInvite = useCallback(async () => {
    try {
      await acceptInvite();

      emitMultiStreamInvitationAnswer(
        multiBroadcastInvite,
        MultiBroadcastAnswerResult.ACCEPTED,
        appState
      );

      removeByType(ToastType.INVITE);
    } catch (error) {
      datadogLogs.logger.error(
        DataDogErrorName.ACCEPT_MULTI_BROADCAST_INVITE,
        {},
        error as Error
      );
    }
  }, [acceptInvite, appState, multiBroadcastInvite, removeByType]);

  const handleRejectInvite = useCallback(async () => {
    try {
      await rejectInvite();

      emitMultiStreamInvitationAnswer(
        multiBroadcastInvite,
        MultiBroadcastAnswerResult.DECLINED,
        appState
      );

      remove(toastId);
    } catch (error) {
      datadogLogs.logger.error(
        DataDogErrorName.REJECT_MULTI_BROADCAST_INVITE,
        {},
        error as Error
      );
    }
  }, [appState, multiBroadcastInvite, rejectInvite, remove, toastId]);

  const handleToastDestroy = useCallback(() => {
    emitMultiStreamInvitationAnswer(
      multiBroadcastInvite,
      MultiBroadcastAnswerResult.EXPIRED,
      appState
    );
  }, [appState, multiBroadcastInvite]);

  useMount(() => {
    emitMultiStreamInvitationDisplayed(multiBroadcastInvite, appState);
  });

  return (
    <ToastWrapper
      className={classnames(styles[breakpoint], styles.toastInviteRoot, {
        [styles.toastInviteRootShader]: isShader,
      })}
      id={toastId}
      lifetime={toastLifeTime}
      type={type}
      onDestroy={handleToastDestroy}
    >
      <div
        className={classnames(
          styles.root,
          styles[breakpoint],
          {
            [styles.shader]: isShader,
          },
          styles.toastInvite
        )}
      >
        <div className={styles.playerContainer}>
          <Player
            ref={videoRef}
            // @ts-ignore
            src={makeAlternativeDomain(hostPreviewUrl)}
            className={styles.player}
            forceDisableHd
            muted
          />
        </div>
        <div className={styles.inviteData}>
          <div className={styles.inviteDataTop}>
            <Typography
              className={styles.inviteMessage}
              type={TYPOGRAPHY_TYPE.PARAGRAPH5}
            >
              <FormattedMessage {...inviteMessage} />
            </Typography>
            {hasBonus && (
              <>
                <Typography type={TYPOGRAPHY_TYPE.HEADLINE7}>{PLUS}</Typography>
                <Typography type={TYPOGRAPHY_TYPE.HEADLINE7}>
                  <FormattedMessage {...messages.bonus} />
                </Typography>
              </>
            )}
          </div>
          <div className={styles.inviteDataBottom}>
            <div className={styles.hostDetails}>
              <Typography
                className={styles.hostDetailsFullName}
                type={TYPOGRAPHY_TYPE.HEADLINE3}
              >
                {hostFullName}
              </Typography>
              <div className={styles.hostDetailsInfographic}>
                <Typography
                  as="div"
                  className={styles.infographicItem}
                  data-testid="points"
                  type={TYPOGRAPHY_TYPE.HEADLINE6}
                >
                  <DiamondIcon />
                  <RoundedNumber value={hostTotalPoint} />
                </Typography>
                <Typography
                  as="div"
                  className={styles.infographicItem}
                  data-testid="points"
                  type={TYPOGRAPHY_TYPE.HEADLINE6}
                >
                  <EyeIcon />
                  {hostViewerCount}
                </Typography>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                onClick={handleRejectInvite}
                size={ButtonSize.CIRCLE_BIG_48}
                variant={buttonVariants.secondary}
              >
                <IconCross />
              </Button>
              <Button
                className={styles.button}
                onClick={handleAcceptInvite}
                size={ButtonSize.CIRCLE_BIG_48}
                variant={buttonVariants.primary}
              >
                <IconChecked />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ToastWrapper>
  );
};

export default memo(ToastInvite);
