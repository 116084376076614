import { tutorialsActions } from "src/features/tutorials/shared/state/slice";
import { TutorialName } from "src/features/tutorials/shared/state/types";

export const completeObsClickToInviteTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.OBS_CLICK_TO_INVITE);

export const completeStickersClickTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STICKERS_CLICK);

export const completeCreateGiftStickerTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.CREATE_GIFT_STICKER);

export const completeStreamsSwipesTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STREAMS_SWIPES);

export const startStreamsSwipesTutorial = () =>
  tutorialsActions.startTutorial(TutorialName.STREAMS_SWIPES);

export const completeWebToAppClickToDownloadTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.WEB_TO_APP_CLICK_TO_DOWNLOAD);
