import { match } from "path-to-regexp";
import { emitEvent } from "@analytics/emit";
import {
  BroadcastType,
  EventFields,
  EventNames,
  MultiBroadcastAnswerResult,
  MultiBroadcastInviteAnchorType,
} from "@analytics/enums";
import { BroadcastStatus } from "src/enums";
import { BroadcastExternalStatus } from "src/features/broadcastExternal/exports/enums";
import { Nullable } from "src/types/common";
import { StreamKind } from "src/types/richFragment/Stream";
import { MultiBroadcastInvite } from "state/tree/broadcast";
import { linkToBroadcast } from "ui/navigation/links";
import { isDocumentVisible } from "utils/isDocumentVisible";

interface AppState {
  broadcastExternalStatus: Nullable<BroadcastExternalStatus>;
  broadcastId: string | undefined;
  broadcastKind: StreamKind | undefined;
  broadcastStatus: string | undefined;
  currentRoute: string;
}

type ParsedAppState =
  | ParsedAppStateWithActiveStream
  | ParsedAppStateWithoutActiveStream;

interface ParsedAppStateWithActiveStream {
  [EventFields.ANCHOR_TYPE]: MultiBroadcastInviteAnchorType.STREAM;
  [EventFields.STREAM_ID]: string;
  [EventFields.STREAM_TYPE]: BroadcastType;
}

interface ParsedAppStateWithoutActiveStream {
  [EventFields.ANCHOR_TYPE]:
    | MultiBroadcastInviteAnchorType.BG
    | MultiBroadcastInviteAnchorType.DEFAULT;
}

interface ParsedInvite {
  [EventFields.INVITATION_ID]: string;
  [EventFields.RELATION_ID]: string;
  [EventFields.SENDER_ID]: string;
  [EventFields.SENDER_ID_STREAM_ID]: string;
}

interface MultiStreamInvitationAnswerData extends ParsedInvite {
  [EventFields.ANCHOR_TYPE]: MultiBroadcastInviteAnchorType;
  [EventFields.RESULT]: MultiBroadcastAnswerResult;
}

type MultiStreamInvitationDisplayedData = ParsedAppState & ParsedInvite;

type MultiStreamInvitationReceivedData = MultiStreamInvitationDisplayedData;

const isActiveBroadcast = (
  broadcastStatus: string | undefined,
  broadcastExternalStatus: Nullable<BroadcastExternalStatus>
) =>
  broadcastStatus === BroadcastStatus.INIT_SUCCEEDED &&
  broadcastExternalStatus === null;

const isActiveExternalBroadcast = (
  broadcastExternalStatus: Nullable<BroadcastExternalStatus>
) => broadcastExternalStatus === BroadcastExternalStatus.ACTIVE;

const parseAppState = ({
  broadcastExternalStatus,
  broadcastId,
  broadcastKind,
  broadcastStatus,
  currentRoute,
}: AppState):
  | ParsedAppStateWithActiveStream
  | ParsedAppStateWithoutActiveStream => {
  const linkToBroadcastMatch = match(linkToBroadcast, { end: false })(
    currentRoute
  );
  const isBroadcastRoute = !!linkToBroadcastMatch;

  if (isBroadcastRoute && broadcastId && broadcastKind === StreamKind.PUBLIC) {
    if (isActiveBroadcast(broadcastStatus, broadcastExternalStatus)) {
      return {
        [EventFields.ANCHOR_TYPE]: MultiBroadcastInviteAnchorType.STREAM,
        [EventFields.STREAM_ID]: broadcastId,
        [EventFields.STREAM_TYPE]: BroadcastType.PUBLIC,
      };
    }

    if (isActiveExternalBroadcast(broadcastExternalStatus)) {
      return {
        [EventFields.ANCHOR_TYPE]: MultiBroadcastInviteAnchorType.STREAM,
        [EventFields.STREAM_ID]: broadcastId,
        [EventFields.STREAM_TYPE]: BroadcastType.OBS,
      };
    }
  }

  return {
    [EventFields.ANCHOR_TYPE]: isDocumentVisible()
      ? MultiBroadcastInviteAnchorType.DEFAULT
      : MultiBroadcastInviteAnchorType.BG,
  };
};

const parseInvite = (
  multiBroadcastInvite: MultiBroadcastInvite
): ParsedInvite => ({
  [EventFields.INVITATION_ID]: multiBroadcastInvite.id,
  [EventFields.RELATION_ID]: multiBroadcastInvite.requestId,
  [EventFields.SENDER_ID]: multiBroadcastInvite.hostAccountId,
  [EventFields.SENDER_ID_STREAM_ID]: multiBroadcastInvite.hostStreamId,
});

export const emitMultiStreamInvitationAnswer = (
  multiBroadcastInvite: MultiBroadcastInvite,
  multiBroadcastAnswerResult: MultiBroadcastAnswerResult,
  appState: AppState
) => {
  const params: MultiStreamInvitationAnswerData = {
    ...parseInvite(multiBroadcastInvite),
    [EventFields.ANCHOR_TYPE]: parseAppState(appState)[EventFields.ANCHOR_TYPE],
    [EventFields.RESULT]: multiBroadcastAnswerResult,
  };

  emitEvent(EventNames.MULTI_STREAM_INVITATION_ANSWER, params);
};

export const emitMultiStreamInvitationDisplayed = (
  multiBroadcastInvite: MultiBroadcastInvite,
  appState: AppState
) => {
  const params: MultiStreamInvitationDisplayedData = {
    ...parseInvite(multiBroadcastInvite),
    ...parseAppState(appState),
  };

  emitEvent(EventNames.MULTI_STREAM_INVITATION_DISPLAYED, params);
};

export const emitMultiStreamInvitationReceived = (
  multiBroadcastInvite: MultiBroadcastInvite,
  appState: AppState
) => {
  const params: MultiStreamInvitationReceivedData = {
    ...parseInvite(multiBroadcastInvite),
    ...parseAppState(appState),
  };

  emitEvent(EventNames.MULTI_STREAM_INVITATION_RECEIVED, params);
};
