import { createListenerMiddleware } from "@reduxjs/toolkit";
import { AcmeServiceName } from "src/enums";
import { tryValidation } from "src/features/signin/state/flows/login";
import { receivedAcme } from "state/actionCreators/connectionManager";
import { RootState } from "state/delegate";
import {
  connectionManagerSelectors,
  deviceInfoSelectors,
} from "state/selectors";

const SERVICE_IDENTIFIERS_FOR_VALIDATION_REQUEST = [
  "removed",
  "newDeviceRemoteLogout",
];

export const validationMiddleware = createListenerMiddleware<RootState>();

validationMiddleware.startListening({
  actionCreator: receivedAcme,
  effect: (action, { dispatch, getState }) => {
    const { serviceName, serviceIdentifier } = action.payload;
    const hasToValidate =
      serviceName === AcmeServiceName.FACILITATOR &&
      SERVICE_IDENTIFIERS_FOR_VALIDATION_REQUEST.includes(serviceIdentifier);

    if (!hasToValidate) {
      return;
    }

    const state = getState();
    const locale = deviceInfoSelectors.getDeviceLocale(state);
    const deviceToken = connectionManagerSelectors.getDeviceToken(state);

    dispatch(tryValidation({ locale, deviceToken }));
  },
});
