import { StorageKeys } from "src/core/analytics/dataMesh/enums";
import { SessionStartParams } from "src/core/analytics/dataMesh/types";
import { addTimeSessionStartToDate } from "src/core/analytics/dataMesh/utils/addTimeSessionStartToDate";
import {
  getAnalyticsSessionDurationInMs,
  getAnalyticsSessionStartRefreshIntervalInMs,
} from "src/core/analytics/imports/environment";

const updateSessionStartDate = () => {
  let sessionStartParams: SessionStartParams = JSON.parse(
    localStorage.getItem(StorageKeys.SESSION_START_PARAMS) || "{}"
  );

  sessionStartParams = {
    [StorageKeys.SESSION_START_ID]:
      sessionStartParams[StorageKeys.SESSION_START_ID],
    [StorageKeys.SESSION_START_TIME]: addTimeSessionStartToDate(
      new Date(),
      getAnalyticsSessionDurationInMs()
    ).getTime(),
  };

  localStorage.setItem(
    StorageKeys.SESSION_START_PARAMS,
    JSON.stringify(sessionStartParams)
  );
};

export const initSessionStartEventRefresh = () => {
  updateSessionStartDate();
  setInterval(
    updateSessionStartDate,
    getAnalyticsSessionStartRefreshIntervalInMs()
  );
};
