import React, {
  CSSProperties,
  SyntheticEvent,
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, LinkProps } from "react-router-dom";
import classnames from "classnames";
import { useProfileAvatarClickTracker } from "chat/components/hooks/useProfileAvatarClickTracker";
import { useProfileAvatarClickTrackerData } from "chat/components/hooks/useProfileAvatarClickTrackerData";
import ChatAvatarColorContext from "chat/context/ChatAvatarColorContext";
import { LiveBadge, PremiumBadge } from "chat/imports/assets";
import { Avatar, EmojiSpan, Typography } from "chat/imports/components";
import {
  AvatarSize,
  StreamKind,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useVipInfo } from "chat/imports/hooks";
import {
  RootState,
  blockedUsersSelectors,
  getPremiumEnabled,
  streamsCacheSelectors,
} from "chat/imports/state";
import { AccountInfo, BasicProfile, VoidCallback } from "chat/imports/types";
import { makeLinkToLiveStream } from "chat/imports/utils";
import makeInitialsFromName from "chat/utils/makeInitialsFromName";
import { useAlternativeDomainContentSupport } from "src/features/chat/hooks/useAlternativeDomainContentSupport";
import { getStreamMaxModerationLevelFullAccess } from "src/state/abTests";
import { settingsSelectors } from "src/state/selectors";
import styles from "./ChatAvatar.scss";

interface ChatAvatarProps {
  accountInfo?: AccountInfo;
  basicProfile?: BasicProfile;
  className?: string;
  classNamePicture?: string;
  conversationId: string;
  isGroupAvatar?: boolean;
  isSmall?: boolean;
  messageSenderId?: string;
  name: string;
  onClick?: VoidCallback;
  pictureUrl: string | undefined;
  to?: LinkProps["to"] | undefined;
}

const ChatAvatar: React.FC<ChatAvatarProps> = ({
  conversationId,
  name,
  to,
  pictureUrl: originalPictureUrl,
  className,
  isGroupAvatar,
  isSmall = false,
  basicProfile,
  accountInfo,
  onClick,
  messageSenderId,
}) => {
  const { conversationIdToColorMap, getRandomChatColor } = useContext(
    ChatAvatarColorContext
  );
  const bgColor = conversationIdToColorMap[conversationId];
  const pictureUrl = useAlternativeDomainContentSupport(originalPictureUrl);

  const { stream, shouldShow } = useSelector(
    useCallback(
      (state: RootState) => ({
        stream: streamsCacheSelectors.getStreamByBroadcasterId(
          state,
          accountInfo?.account_id
        ),
        shouldShow: !blockedUsersSelectors.isBlockedUser(
          state,
          accountInfo?.account_id
        ),
      }),
      [accountInfo?.account_id]
    ),
    shallowEqual
  );

  const isStreamNotAllowed = useSelector(
    (state: RootState) =>
      getStreamMaxModerationLevelFullAccess(state) < stream?.moderationLevel
  );

  const isAccessToPremiumEnabled = useSelector((state) =>
    Boolean(Number(settingsSelectors.isAccessToPremiumEnabled(state)))
  );

  const isPremiumEnabled = useSelector(getPremiumEnabled);

  const targetData = useProfileAvatarClickTrackerData({
    conversationId,
    isStreamAvailableForUser: shouldShow,
    messageSenderId,
    stream,
  });

  const trackProfileAvatarClick = useProfileAvatarClickTracker(targetData);

  useLayoutEffect(() => {
    if (bgColor || pictureUrl) {
      return;
    }
    getRandomChatColor(conversationId);
  }, [bgColor, conversationId, getRandomChatColor, pictureUrl]);

  const { vipLabel } = useVipInfo({ vipLevel: accountInfo?.vip_level });
  const isUserStreaming = stream && shouldShow;

  const preparedName = useMemo(() => {
    if (pictureUrl) {
      return null;
    }

    return makeInitialsFromName(name);
  }, [name, pictureUrl]);

  const content = useMemo(() => {
    if (isGroupAvatar) {
      return pictureUrl ? (
        <img
          className={styles.picture}
          src={pictureUrl}
          alt="chat-picture"
          data-testid="group-avatar"
        />
      ) : (
        <EmojiSpan>{preparedName}</EmojiSpan>
      );
    }

    return (
      <Avatar
        className={styles.picture}
        basicProfile={basicProfile}
        vipLabel={vipLabel}
        accountId={accountInfo?.account_id}
        size={isSmall ? AvatarSize.SMALL : AvatarSize.SMALL_PLUS}
      />
    );
  }, [
    accountInfo?.account_id,
    basicProfile,
    isGroupAvatar,
    pictureUrl,
    preparedName,
    vipLabel,
    isSmall,
  ]);

  const commonProps = {
    type: isSmall ? TYPOGRAPHY_TYPE.HEADLINE4 : TYPOGRAPHY_TYPE.HEADLINE3,
    className: classnames(styles.root, className, { [styles.small]: isSmall }),
    style:
      !pictureUrl && bgColor
        ? ({
            "--chat-bg-color": bgColor,
          } as CSSProperties)
        : undefined,
    onClick: (event: SyntheticEvent) => {
      if (trackProfileAvatarClick) {
        event.stopPropagation();
        trackProfileAvatarClick();
      }
      onClick?.();
    },
  };

  if (isUserStreaming) {
    const linkToLiveStream = makeLinkToLiveStream(stream.id);

    return (
      <Typography
        {...commonProps}
        className={classnames(commonProps.className, styles.avatarWithBadge)}
        // @ts-expect-error
        to={
          isStreamNotAllowed ||
          (stream?.kind !== StreamKind.PUBLIC &&
            (!isAccessToPremiumEnabled || !isPremiumEnabled))
            ? to
            : linkToLiveStream
        }
        as={Link}
      >
        {!isStreamNotAllowed &&
          (stream.kind === StreamKind.PUBLIC ? (
            <LiveBadge
              className={classnames(styles.badge, styles.badgeLive)}
              data-testid="avatar-live-label"
            />
          ) : (
            isAccessToPremiumEnabled &&
            isPremiumEnabled && (
              <PremiumBadge
                className={classnames(styles.badge, styles.badgePremium)}
                data-testid="avatar-premium-label"
              />
            )
          ))}
        <div className={styles.streamWithContent}>{content}</div>
      </Typography>
    );
  }

  return to != null ? (
    <Typography {...commonProps} to={to} as={Link}>
      {content}
    </Typography>
  ) : (
    <Typography {...commonProps} as="div">
      {content}
    </Typography>
  );
};

export default memo(ChatAvatar);
