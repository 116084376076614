import { createSlice } from "@reduxjs/toolkit";
import { getMosV2Action } from "src/features/mos/state/getMosV2Action";
import { MosError, MosV2State } from "src/features/mos/types";

const initialState: MosV2State = {
  error: null,
  loading: false,
  items: [],
  lineupId: null,
};

const slice = createSlice({
  name: "mosV2",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMosV2Action.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getMosV2Action.fulfilled, (state, { payload }) => {
        state.items = payload.items;
        state.lineupId = payload.lineupId;
        state.loading = false;
      })
      .addCase(getMosV2Action.rejected, (state, { payload }) => {
        state.error = payload?.message ?? MosError.SOMETHING_WENT_WRONG;
        state.loading = false;
      });
  },
});

export const mosV2Reducer = slice.reducer;
