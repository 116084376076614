import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { isSimdSupported as getIsSimdSupported } from "@banuba/webar";
import {
  getBanubaSDKRestrictedIOSVersions,
  getIsBanubaSDKAndroidEnabled,
  getIsBanubaSDKEnabled,
  getIsBanubaSDKMobileEnabled,
} from "src/features/broadcastMasks/soc/broadcastMasksSoc";
import { Breakpoints, DeviceType } from "src/features/stream/imports/enums";
import { useBreakpointPrecise } from "src/features/stream/imports/hooks";
import { deviceInfoSelectors } from "src/features/stream/imports/state";
import { RootState } from "src/features/stream/imports/types";

const selectors = (state: RootState) => ({
  isBanubaSDKEnabled: getIsBanubaSDKEnabled(state),
  isBanubaSDKMobileEnabled: getIsBanubaSDKMobileEnabled(state),
  isBanubaSDKAndroidEnabled: getIsBanubaSDKAndroidEnabled(state),
  restrictedIOSVersions: getBanubaSDKRestrictedIOSVersions(state),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  deviceVersion: deviceInfoSelectors.getDeviceVersion(state),
});

export const useBanubaSDKEnabled = () => {
  const breakpoint = useBreakpointPrecise();
  const [isSimdSupported, setIsSimdSupported] = useState(false);

  const {
    isBanubaSDKEnabled,
    isBanubaSDKMobileEnabled,
    isBanubaSDKAndroidEnabled,
    restrictedIOSVersions,
    deviceType,
    deviceVersion,
  } = useSelector(selectors, shallowEqual);

  useEffect(() => {
    getIsSimdSupported().then(setIsSimdSupported);
  }, []);

  return useMemo(() => {
    if (!isBanubaSDKEnabled || !isSimdSupported) {
      return false;
    }

    if (breakpoint === Breakpoints.DESKTOP) {
      return true;
    }

    if (deviceType === DeviceType.IOS && deviceVersion) {
      const isIOSVersionRestricted =
        restrictedIOSVersions.includes(deviceVersion);

      return isBanubaSDKMobileEnabled && !isIOSVersionRestricted;
    }

    if (deviceType === DeviceType.ANDROID) {
      return isBanubaSDKMobileEnabled && isBanubaSDKAndroidEnabled;
    }

    return isBanubaSDKMobileEnabled;
  }, [
    breakpoint,
    isBanubaSDKEnabled,
    isBanubaSDKMobileEnabled,
    isBanubaSDKAndroidEnabled,
    isSimdSupported,
    restrictedIOSVersions,
    deviceType,
    deviceVersion,
  ]);
};
