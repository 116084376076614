import React, { useCallback } from "react";
import AppleLogin from "react-apple-login";
import { batch, useDispatch } from "react-redux";
import {
  EventFields,
  SCREEN_NAME,
  SIGNIN_TARGET,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import {
  getAppleClientId,
  getAppleProviderEnabled,
} from "src/features/signin/imports/environment";
import {
  lockTopModal,
  unlockTopModal,
} from "src/features/signin/imports/state";
import { ButtonVariant } from "src/features/signin/imports/ui";
import { getAppleRedirectURI } from "src/features/signin/imports/utils";
import {
  AppleResponse,
  LoginAppleButtonProps,
} from "src/features/signin/modal/login/components/LoginAppleButton/types";
import ProviderButton from "src/features/signin/modal/login/components/ProviderButton";
import { loginWithAppleProvider } from "src/features/signin/state/flows/loginWithAppleProvider";
import {
  loginFailed,
  loginHideInProgressFlag,
  loginStart,
} from "src/features/signin/state/login/actionCreators";
import { ComponentWithClassName } from "src/types/common";

const LoginAppleButton: ComponentWithClassName<LoginAppleButtonProps> = ({
  buttonVariant = ButtonVariant.SECONDARY,
  provider,
  sendCustomAnalyticsEvent,
  shouldSkipErrorHandling = false,
  type,
  loginTypeVariant,
  className,
  buttonText,
  onLoginSuccess,
  registrationSource,
}) => {
  const dispatch = useDispatch();

  const onAppleResponse = useCallback(
    (response: AppleResponse) => {
      if (response.authorization) {
        dispatch(
          loginWithAppleProvider({
            loginParams: response.authorization,
            onLoginSuccess,
          })
        );
      }
      if (response.error) {
        dispatch(unlockTopModal());
        shouldSkipErrorHandling
          ? dispatch(loginHideInProgressFlag())
          : dispatch(loginFailed(response.error?.error));
      }
    },
    [onLoginSuccess, dispatch, shouldSkipErrorHandling]
  );

  const onStartAppleLogin = useCallback(
    (callback) => {
      if (sendCustomAnalyticsEvent) {
        sendCustomAnalyticsEvent();
      } else {
        emitUiAction({
          target: SIGNIN_TARGET.SIGNIN_WITH_APPLE,
          tango_screen: {
            reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
          },
          additionalParams: {
            [EventFields.SOURCE]: registrationSource,
          },
        });
      }

      batch(() => {
        dispatch(lockTopModal());
        dispatch(loginStart());
      });

      callback();
    },
    [dispatch, sendCustomAnalyticsEvent]
  );

  const getOnProviderClick = useCallback(
    (renderProps) => () => onStartAppleLogin(renderProps.onClick),
    [onStartAppleLogin]
  );

  const renderButton = useCallback(
    (renderProps) => (
      <ProviderButton
        buttonVariant={buttonVariant}
        key={provider}
        provider={provider}
        loginTypeButtonVariant={loginTypeVariant}
        onClick={getOnProviderClick(renderProps)}
        type={type}
        className={className}
        buttonText={buttonText}
        onLoginSuccess={onLoginSuccess}
      />
    ),
    [onLoginSuccess, onStartAppleLogin, getOnProviderClick]
  );

  if (
    !getAppleClientId() ||
    !getAppleRedirectURI() ||
    !getAppleProviderEnabled()
  ) {
    return null;
  }

  return (
    <AppleLogin
      clientId={getAppleClientId()}
      redirectURI={getAppleRedirectURI()}
      scope="email name"
      usePopup
      callback={onAppleResponse}
      responseMode="form_post"
      render={renderButton}
    />
  );
};

export default LoginAppleButton;
