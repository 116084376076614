import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ExternalMessageText from "chat/components/common/ExternalMessageText";
import IdentityValidationMessageHeader from "chat/components/common/IdentityValidationMessageHeader";
import SystemChatMessage from "chat/components/common/SystemChatMessage";
import { messages, previewMessages } from "chat/components/common/messages";
import { CallMessagePreview } from "chat/components/currentConversation/components/CallMessagePreview";
import { GiftMessagePreviewText } from "chat/components/currentConversation/components/GiftMessagePreviewText";
import { ScreenshotMessageText } from "chat/components/currentConversation/components/ScreenshotMessageText";
import { systemMessageTypes } from "chat/constants";
import { DisplayName, EmojiSpan, Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import {
  RootState,
  batchLoadProfiles,
  getIsGifMessageEnabled,
  getIsOfflineChatMediaContentEnabled,
  getPremiumMessagesEnabled,
  profilesCacheSelectors,
  userSelectors,
} from "chat/imports/state";
import { WithRequired } from "chat/imports/types";
import { Group, Message, MessageType } from "chat/types";
import styles from "./Preview.scss";

const UnsupportedMessage = () => (
  <FormattedMessage
    id="chat.conversations-list.preview.unsupported-message"
    defaultMessage="Unsupported message"
  />
);

interface PreviewProps {
  className?: string;
  groupInfo: Group | undefined;
  message: WithRequired<Message, "type">;
}

const Preview: FC<PreviewProps> = ({ message, groupInfo, className }) => {
  const dispatch = useDispatch();

  const basicProfile = useSelector(
    useCallback(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(state, message.from),
      [message.from]
    ),
    shallowEqual
  );
  const isPremiumMessagesEnabled = useSelector(getPremiumMessagesEnabled);
  const isGifMessageEnabled = useSelector(getIsGifMessageEnabled);
  const isMediaContentEnabled = useSelector(
    getIsOfflineChatMediaContentEnabled
  );
  const accountId = useSelector(userSelectors.getMyAccountId);
  const isMyMessage = accountId === message.from;

  useEffect(() => {
    if (basicProfile) {
      return;
    }

    dispatch(
      batchLoadProfiles({
        ids: [message.from],
        loadOnlyIfMissing: true,
        params: { basic: true, detail: false, live: false, follow: false },
      })
    );
  }, [basicProfile, message.from, dispatch]);

  const content = useMemo(() => {
    if (systemMessageTypes.includes(message.type)) {
      return (
        <SystemChatMessage message={message} basicProfile={basicProfile} />
      );
    }
    switch (message.type) {
      case MessageType.TEXT_MESSAGE:
        return message.body;
      case MessageType.SDK_EXTERNAL_MESSAGE:
        return message.sdk_message_payload?.payload ? (
          <ExternalMessageText
            protobufPayload={message.sdk_message_payload.payload}
          />
        ) : null;
      case MessageType.KYC_VERIFICATION_REQUESTED:
        return (
          <div className={styles.kycRequestedPreview}>
            <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
              <IdentityValidationMessageHeader />{" "}
              <FormattedMessage {...messages.text} />
            </Typography>
          </div>
        );
      case MessageType.DEVICE_LOGIN_INFO_MESSAGE:
        return (
          <FormattedMessage
            id="device.login_info.message.preview_text"
            defaultMessage="We detected a login to your account from a new device"
          />
        );
      case MessageType.SUBSCRIPTION:
        return (
          <FormattedMessage
            id="chat.conversations-list.preview.subscription"
            defaultMessage="Subscription update!"
          />
        );

      case MessageType.PROFILE_MESSAGE:
      case MessageType.LIVE_STREAM:
        return <FormattedMessage {...previewMessages[message.type]} />;
      case MessageType.VIDEO_MESSAGE:
      case MessageType.IMAGE_MESSAGE:
      case MessageType.VIDEO_PTT: {
        if (!isMediaContentEnabled && !isMyMessage) {
          return <UnsupportedMessage />;
        }

        return <FormattedMessage {...previewMessages[message.type]} />;
      }
      case MessageType.PREMIUM_MESSAGE_SHARED:
        if (
          !isPremiumMessagesEnabled ||
          (!isMediaContentEnabled && !isMyMessage)
        ) {
          return <UnsupportedMessage />;
        }

        return <FormattedMessage {...previewMessages[message.type]} />;
      case MessageType.SCREENSHOT_INFO_MESSAGE: {
        if (message.from) {
          return <ScreenshotMessageText authorAccountId={message.from} />;
        }

        return <UnsupportedMessage />;
      }
      case MessageType.GIFT_IN_CHAT: {
        return <GiftMessagePreviewText message={message} />;
      }
      case MessageType.MISSED_CALL_MESSAGE:
      case MessageType.NORMAL_CALL_MESSAGE: {
        if (message.call_log_payload) {
          return (
            <CallMessagePreview messagePayload={message.call_log_payload} />
          );
        }

        return <UnsupportedMessage />;
      }
      case MessageType.GIF_MESSAGE: {
        if (!isGifMessageEnabled || (!isMediaContentEnabled && !isMyMessage)) {
          return <UnsupportedMessage />;
        }

        return <FormattedMessage {...previewMessages[message.type]} />;
      }
      default:
        return <UnsupportedMessage />;
    }
  }, [
    message,
    basicProfile,
    isPremiumMessagesEnabled,
    isMediaContentEnabled,
    isMyMessage,
    isGifMessageEnabled,
  ]);

  const shouldShowAuthorName =
    groupInfo &&
    !systemMessageTypes.includes(message.type) &&
    // Screenshot message already contains author name in the message text
    message.type !== MessageType.SCREENSHOT_INFO_MESSAGE;

  if (!basicProfile) {
    return null;
  }

  return (
    <EmojiSpan className={className}>
      {shouldShowAuthorName && (
        <>
          <DisplayName short basicProfile={basicProfile} />
          {": "}
        </>
      )}
      {content}
    </EmojiSpan>
  );
};

export default memo(Preview);
