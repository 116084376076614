import {
  type PayloadAction,
  SerializedError,
  createSlice,
} from "@reduxjs/toolkit";
import {
  DEFAULT_PAGE_SIZE,
  TOP_USERS_NUM_MOBILE,
} from "src/features/referralProgram/common/constants";
import {
  AgencyLevel,
  Currency,
  ReferralType,
  ReferredUsersOrder,
  ReferredUsersSortBy,
  TimeRange,
  UserType,
} from "src/features/referralProgram/common/enums";
import {
  ActiveTear,
  QueryParams,
  ReferredUser,
  ReferredUsersMetaData,
  Tier,
} from "src/features/referralProgram/common/types";
import { loadReferralUsersList } from "src/features/referralProgram/state/asyncActions";
import { getProjectedTierIndex } from "src/features/referralProgram/utils/getProjectedTierIndex";
import { Nullable } from "src/types/common";
import { ApplicationThunk } from "state/types";

export const INITIAL_QUERY_PARAMS: QueryParams = {
  referralType: ReferralType.CREATORS,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  order: ReferredUsersOrder.DESC,
  sortBy: ReferredUsersSortBy.EARNED_POINTS,
  userType: UserType.ALL,
  timeRange: TimeRange.CURRENT_MONTH,
  username: "",
};

export interface ReferralProgramState {
  currency: Currency;
  error: Nullable<SerializedError>;
  loading: boolean;
  meta: ReferredUsersMetaData;
  queryParams: QueryParams;
  referredUsers: Array<ReferredUser>;
  selectedTierIndex: number;
  tier: ActiveTear;
  tiers: Tier[];
  topReferredUsers: Array<ReferredUser>;
}

export const initialState: ReferralProgramState = {
  referredUsers: [],
  topReferredUsers: [],
  meta: {
    pageCount: 1,
    totalEarnedPoints: 0,
    totalNewUsers: 0,
    totalPoints: 0,
    totalRedeemPoints: 0,
    totalUsers: 0,
    totalStreamedDays: 0,
    totalStreamedSeconds: 0,
    totalUsd: 0,
    totalEarnedUsd: 0,
    totalRedeemUsd: 0,
  },
  tiers: [],
  tier: {
    currentMonthRevenuePoints: 0,
    currentTier: AgencyLevel.ROOKIE,
    projectedNextTier: AgencyLevel.BRONZE,
  },
  loading: false,
  error: null,
  queryParams: INITIAL_QUERY_PARAMS,
  currency: Currency.DIAMONDS,
  selectedTierIndex: 0,
};

const slice = createSlice({
  name: "referralProgram",
  initialState,
  reducers: {
    setQueryParams(state, action: PayloadAction<Partial<QueryParams>>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setSelectedTierIndex(state, action: PayloadAction<number>) {
      state.selectedTierIndex = action.payload;
    },
    setCurrency(state, action: PayloadAction<Currency>) {
      state.currency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReferralUsersList.fulfilled, (state, action) => {
      state.loading = false;
      state.referredUsers = action.payload.data;
      state.topReferredUsers = action.payload.data.slice(
        0,
        TOP_USERS_NUM_MOBILE
      );
      state.meta = action.payload.meta;

      if (action.payload.meta.tiers?.length && action.payload.meta.tier) {
        state.tiers = action.payload.meta.tiers;
        state.tier = action.payload.meta.tier;
        state.selectedTierIndex = getProjectedTierIndex(action.payload.meta);
      }
    });
    builder.addCase(loadReferralUsersList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadReferralUsersList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { setQueryParams, setSelectedTierIndex, setCurrency } =
  slice.actions;

export const referralProgramReducer = slice.reducer;

export const changeQueryParams =
  (queryParams: Partial<QueryParams>): ApplicationThunk =>
  (dispatch) => {
    dispatch(setQueryParams(queryParams));
    dispatch(loadReferralUsersList());
  };

export const resetQueryParams = (): ApplicationThunk => (dispatch) => {
  dispatch(changeQueryParams(INITIAL_QUERY_PARAMS));
};
