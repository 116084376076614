import { createListenerMiddleware } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { ToastType } from "src/enums";
import { sharedMessages } from "src/features/signin/imports/ui";
import {
  RichStickerEvent,
  StickerStatus,
} from "src/types/richFragment/Sticker";
// eslint-disable-next-line no-restricted-imports
import { VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT } from "state/actionTypes";
import { RootState } from "state/delegate";
import { broadcastSelectors } from "state/selectors";
import { addToast } from "state/tree/toast";

export const pullEventListenerMiddleware =
  createListenerMiddleware<RootState>();

pullEventListenerMiddleware.startListening({
  type: VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  effect: async (action: AnyAction, { dispatch, getState }) => {
    if (action.error) {
      return;
    }
    const broadcastId = broadcastSelectors.broadcastId(getState());

    const { stickersEvents } = action.payload;
    if (stickersEvents) {
      stickersEvents.forEach(({ sticker }: RichStickerEvent) => {
        if (
          sticker.status === StickerStatus.MODERATION_FAILED &&
          broadcastId === action.meta.streamId
        ) {
          dispatch(
            addToast({
              type: ToastType.REGULAR,
              title: sharedMessages.stickerModeration,
            })
          );
        }
      });
    }
  },
});
