import { useSelector } from "react-redux";
import { getGetTheAppAppStoreUrl } from "src/features/marketing/imports/environment";
import {
  getGooglePlayApkLink,
  getIsOneLinkEnabled,
} from "src/features/marketing/imports/state";
import {
  handleGetTheAppAppStoreUrl,
  handleGetTheAppGooglePlayUrl,
} from "src/features/marketing/imports/utils";

export const useGetDirectStoreUrl = () => {
  const isOneLinkEnabled = useSelector(getIsOneLinkEnabled);
  const googlePlayApkLink = useSelector(getGooglePlayApkLink);

  return {
    googlePlayLink: isOneLinkEnabled
      ? handleGetTheAppGooglePlayUrl()
      : googlePlayApkLink,
    appStoreLink: isOneLinkEnabled
      ? handleGetTheAppAppStoreUrl()
      : getGetTheAppAppStoreUrl(),
  };
};
