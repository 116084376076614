import { v4 as uuidv4 } from "uuid";
import { ClientSessionEvent } from "generated/proto/ClientSessionEventModel";
import { ClientEventsRequest } from "generated/proto/EventGateway";
import dataMesh from "src/core/analytics/dataMesh/dataMeshModule";
import {
  ClientSessionFields,
  EventNames,
  StorageKeys,
} from "src/core/analytics/dataMesh/enums";
import { SessionStartParams } from "src/core/analytics/dataMesh/types";
import { initSessionStartEventRefresh } from "src/core/analytics/dataMesh/utils/initSessionStartEventRefresh";
import { Platforms } from "src/core/analytics/enums";
import { getEnvironmentName } from "src/core/analytics/imports/environment";
import { Nullable } from "src/core/analytics/imports/types";
import {
  currentTimeMillis,
  getInMemoryStore,
} from "src/core/analytics/imports/utils";
import { getAnalyticsPluginGatewayEnabled } from "state/abTests";

let sessionStartParams: SessionStartParams = JSON.parse(
  localStorage.getItem(StorageKeys.SESSION_START_PARAMS) || "{}"
);

let clientSessionId: Nullable<string> =
  sessionStartParams[StorageKeys.SESSION_START_ID];

const analyticsSessionStart = (): void => {
  const timestamp = currentTimeMillis();

  const getSessionStartTime =
    sessionStartParams[StorageKeys.SESSION_START_TIME];

  initSessionStartEventRefresh();

  if (
    clientSessionId &&
    !!getSessionStartTime &&
    timestamp < Number(getSessionStartTime)
  ) {
    return;
  }

  clientSessionId = uuidv4();

  sessionStartParams = {
    [StorageKeys.SESSION_START_TIME]:
      sessionStartParams[StorageKeys.SESSION_START_TIME],
    [StorageKeys.SESSION_START_ID]: clientSessionId,
  };

  localStorage.setItem(
    StorageKeys.SESSION_START_PARAMS,
    JSON.stringify(sessionStartParams)
  );

  const {
    marketing: { analyticsKey },
    deviceInfo: { type, browserName, version, locale },
    // @ts-ignore TODO: WEB-8260 remove when src/state/tree/user.js will be moved to ts
    user: { accountId },
    // @ts-ignore TODO: WEB-8322 remove when src/state/tree/connectionManager.js will be moved to ts
    connectionManager: { username },
  } = getInMemoryStore();

  const payload = {
    [ClientSessionFields.DEVICE_CATEGORY]: type,
    [ClientSessionFields.PLATFORM]: Platforms.WEB,
    [ClientSessionFields.BROWSER]: browserName,
    [ClientSessionFields.BROWSER_VERSION]: version,
    [ClientSessionFields.DEVICE_LANGUAGE]: locale,
    [ClientSessionFields.USERNAME]: username,
    [ClientSessionFields.VISITOR_ID]: analyticsKey,
    [ClientSessionFields.TANGO_VERSION]: GENERATED_APP_INFO.fullVersion,
  };

  const parameters = {
    uid: clientSessionId,
    clientSessionId,
    eventTime: timestamp,
    eventSource: Platforms.WEB,
    screenName: "",
    screenState: "",
  };

  const body = ClientEventsRequest.encode({
    events: [
      {
        name: EventNames.CLIENT_SESSION,
        payload: ClientSessionEvent.encode(payload).finish(),
        parameters,
        accountId,
      },
    ],
  }).finish();

  dataMesh.then((analytics) => {
    analytics.track(EventNames.CLIENT_SESSION, body);
  });
};

interface Parameters {
  screenName: string;
  screenState: string;
}

const dataMeshTrackEvent = (
  eventName: EventNames,
  payload: Uint8Array,
  params?: Partial<Parameters>
) => {
  const isAnalyticsPluginGatewayEnabled =
    getAnalyticsPluginGatewayEnabled(getInMemoryStore());

  if (!isAnalyticsPluginGatewayEnabled) {
    return;
  }

  if (!clientSessionId) {
    analyticsSessionStart();
  }

  const timestamp = currentTimeMillis();
  const eventUid = uuidv4();
  const {
    // @ts-ignore TODO: WEB-8260 remove when src/state/tree/user.js will be moved to ts
    user: { accountId },
  } = getInMemoryStore();

  const parameters = {
    uid: eventUid,
    clientSessionId,
    env: getEnvironmentName(),
    eventTime: timestamp,
    eventSource: Platforms.WEB,
    screenName: "",
    screenState: "",
    ...params,
  };

  const body = ClientEventsRequest.encode({
    events: [
      {
        name: eventName,
        payload,
        parameters,
        accountId,
      },
    ],
  }).finish();

  dataMesh.then((analytics) => {
    analytics.track(eventName, body);
  });
};

export { analyticsSessionStart, dataMeshTrackEvent };
