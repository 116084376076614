import { GetUserHashedInfoResponse } from "src/features/marketing/api/marketing";
import {
  ApplicationThunk,
  EventFields,
  GeneralEventFields,
  StorageKeys,
  VoidCallback,
} from "src/features/marketing/imports";
import {
  emitEvent,
  tangoAnalytics,
} from "src/features/marketing/imports/utils";
import { loadUserHashedInfo } from "src/features/marketing/state/asyncAction";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { userSelectors } from "state/selectors";

interface RegistrationParams {
  [GeneralEventFields.COMMENT]: string;
  [GeneralEventFields.LOGIN_PROVIDER]: string;
  [GeneralEventFields.USER_ID]: string;
}

type BIEventCallback = (
  userHashedInfoResponse: GetUserHashedInfoResponse,
  accountId: string,
  reg_type?: string
) => void;

export const sendMarketingEventFlow =
  (
    emitMarketingBIEvent: BIEventCallback | VoidCallback,
    actionName?: string,
    params?: RegistrationParams
  ): ApplicationThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const analyticsKey = marketingSelectors.getAnalyticsKey(state);
      const userHashedInfo = marketingSelectors.getUserHashedInfo(state);
      const myAccountId = userSelectors.getMyAccountId(state);
      const deduplicationKey = analyticsKey || myAccountId;

      if (!userHashedInfo?.hashedPhoneNumber && !userHashedInfo?.hashedEmail) {
        const userHashedInfoResponse =
          await dispatch(loadUserHashedInfo()).unwrap();

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfoResponse?.hashedEmail,
            [EventFields.HASHP]: userHashedInfoResponse?.hashedPhoneNumber,
          })
        );

        if (params) {
          emitEvent(actionName, {
            ...params,
            hashedEmail: userHashedInfoResponse.hashedEmail,
            hashedPhoneNumber: userHashedInfoResponse.hashedPhoneNumber,
          });
        }

        emitMarketingBIEvent(
          userHashedInfoResponse,
          deduplicationKey,
          params?.reg_type
        );
      } else {
        emitMarketingBIEvent(
          userHashedInfo,
          deduplicationKey,
          params?.reg_type
        );

        tangoAnalytics.then((analytics) =>
          analytics.Local.setItem(StorageKeys.ROUTE_DATA, {
            [EventFields.HASHE]: userHashedInfo?.hashedEmail,
            [EventFields.HASHP]: userHashedInfo?.hashedPhoneNumber,
          })
        );
      }
    } catch (err) {
      if (params) {
        emitEvent(actionName, params);
      }
    }
  };
