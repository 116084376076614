import { PayloadAction } from "@reduxjs/toolkit";

export type TutorialAction = PayloadAction<TutorialName>;

export enum TutorialName {
  CREATE_GIFT_STICKER = "stickers:createGiftSticker",
  OBS_CLICK_TO_INVITE = "obs:clickToInvite",
  STICKERS_CLICK = "stickers:click",
  STREAMS_SWIPES = "streams:swipes",
  WEB_TO_APP_CLICK_TO_DOWNLOAD = "webToApp:clickToDownload",
}

export enum TutorialState {
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  STARTED = "STARTED",
}

export type TutorialsState = {
  [key in TutorialName]: TutorialState;
};
