export enum StreamStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  KICKED = "KICKED",
  LEFT = "LEFT",
}

export const deadMultiBroadcastStreamStatuses = [
  StreamStatus.TERMINATED,
  StreamStatus.KICKED,
  StreamStatus.LEFT,
];
