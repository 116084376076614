import React, { memo } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import {
  CoinIcon,
  TYPOGRAPHY_TYPE,
  Typography,
} from "src/features/chat/imports/components";
import {
  CoinIconSize,
  GIFT_ORIGIN,
  RealTimeRecommendationsGiftFromType,
} from "src/features/chat/imports/constants";
import {
  useBreakpointMobileLayout,
  useBreakpointPrecise,
  useMakeAlternativeDomainUrl,
} from "src/features/chat/imports/hooks";
import { getGiftsAlternativeDomainContentSupportEnabled } from "src/features/chat/imports/state";
import { GiftChat } from "src/features/chat/imports/types";
import sendGift, { sendGiftSchedule } from "state/flows/sendGift";
import styles from "src/features/chat/components/Mos/MosGift.scss";

interface MosProps {
  gift: GiftChat;
}

export const MosGiftComponent = ({ gift }: MosProps) => {
  const isMobile = useBreakpointMobileLayout();
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  const handleGiftClick = () => {
    sendGiftSchedule(() =>
      dispatch(
        // @ts-ignore https://tango-me.atlassian.net/browse/WEB-8226
        sendGift({
          giftId: gift.id,
          isMobile,
          giftSelectionOrigin: GIFT_ORIGIN.MOS,
          realTimeGiftFromType: RealTimeRecommendationsGiftFromType.GIFT_MOODS,
        })
      )
    );
  };

  return (
    <div
      className={classnames(styles.giftBlock, styles[breakpoint])}
      data-testid={`mos-item-${gift.id}`}
      onClick={handleGiftClick}
    >
      <img
        src={makeAlternativeDomainUrl(gift.icon)}
        className={styles.giftIcon}
        alt="mos"
      />
      <div className={styles.infoBlock}>
        <CoinIcon
          className={classnames(styles.coinIcon, styles[CoinIconSize.SMALL])}
        />
        <Typography type={TYPOGRAPHY_TYPE.MINI}>
          {gift.priceInCredit}
        </Typography>
      </div>
    </div>
  );
};

export const MosGift = memo(MosGiftComponent);
