import { sendEventToGateway } from "src/core/analytics/dataMesh/api/dataMeshEventsApi";
import { getInMemoryStore } from "src/core/analytics/imports/utils";

interface Payload {
  event: string;
  properties: BodyInit;
  type: string;
}

export const dataMeshPlugin = () => ({
  NAMESPACE: "data-mesh-plugin",
  track: ({ payload }: { payload: Payload }) => {
    sendEventToGateway({
      isUserLoggedIn: getInMemoryStore()?.login?.loggedIn,
      body: payload.properties,
    });
  },
  loaded: () => true,
});
