import Analytics, { AnalyticsInstance } from "@tango-analytics/analytics";
import { dataMeshPlugin } from "src/core/analytics/dataMesh/dataMeshPlugin";
import {
  BIInLocalStorage,
  BIInSessionStorage,
  StorageAdvanced,
} from "src/core/analytics/storage";

interface AnalyticsType extends AnalyticsInstance {
  Local: StorageAdvanced;
  Session: StorageAdvanced;
}

const dataMesh = async () => {
  const analytics = <AnalyticsType>Analytics({
    app: "data-mesh-module",
    plugins: [dataMeshPlugin()],
  });

  analytics.Session = BIInSessionStorage;

  analytics.Local = BIInLocalStorage;

  return analytics;
};

export default dataMesh();
