import {
  AccountInfo,
  GiftId,
  MosItem,
  MosUiItem,
  Nullable,
} from "chat/imports/types";

export enum MessageType {
  AUDIO_MESSAGE = "AUDIO_MESSAGE",
  CHAT_UNLOCKED = "CHAT_UNLOCKED",
  DEEPLINK_MESSAGE = "DEEPLINK_MESSAGE", // message sent by SCREAM (???), check if needed
  DEVICE_LOGIN_INFO_MESSAGE = "DEVICE_LOGIN_INFO_MESSAGE",
  FAMILY_INVITE_MESSAGE = "FAMILY_INVITE_MESSAGE",
  GIF_MESSAGE = "GIF_MESSAGE",
  GIFT_IN_CHAT = "GIFT_IN_CHAT", // using payload
  GO_LIVE_TO_BC_MESSAGE = "GO_LIVE_TO_BC_MESSAGE", // no payload
  GROUP_MEMBER_JOIN = "GROUP_MEMBER_JOIN",
  GROUP_MEMBER_LEAVE = "GROUP_MEMBER_LEAVE",
  GROUP_NAME_CHANGE = "GROUP_NAME_CHANGE",
  GROUP_PICTURE_CHANGE = "GROUP_PICTURE_CHANGE",
  GROUP_SETTING_CHANGED = "GROUP_SETTING_CHANGED",
  GROUP_UPDATE = "GROUP_UPDATE", // invisible, used only to notify client group muted setting is changed via regular ts-based request
  HAPPY_MOMENT_MESSAGE = "HAPPY_MOMENT_MESSAGE",
  IMAGE_MESSAGE = "IMAGE_MESSAGE",
  KYC_VERIFICATION_REQUESTED = "KYC_VERIFICATION_REQUESTED",
  LIKE_MESSAGE = "LIKE_MESSAGE", // invisible, returned separately
  LIVE_STREAM = "LIVE_STREAM", // chat stream message, no payload
  MISSED_CALL_MESSAGE = "MISSED_CALL_MESSAGE",
  NORMAL_CALL_MESSAGE = "NORMAL_CALL_MESSAGE",
  PHOTO_SAVED_INFO_MESSAGE = "PHOTO_SAVED_INFO_MESSAGE",
  PREMIUM_MESSAGE_SHARED = "PREMIUM_MESSAGE_SHARED",
  PROFILE_MESSAGE = "PROFILE_MESSAGE", // share profile message, using payload
  REFERRAL_MESSAGE = "REFERRAL_MESSAGE",
  SCREENSHOT_INFO_MESSAGE = "SCREENSHOT_INFO_MESSAGE",
  SDK_EXTERNAL_MESSAGE = "SDK_EXTERNAL_MESSAGE", // used to sent messages from SCREAM, SUBSCRIPTION
  SOCIAL_POST_MESSAGE = "SOCIAL_POST_MESSAGE", // feed repost message, using payload
  STICKER_MESSAGE = "STICKER_MESSAGE", // regular sticker, using payload
  SUBSCRIPTION = "SUBSCRIPTION", // !!! payload is sent via BODY as JSON
  TANGO_SURPRISE_MESSAGE = "TANGO_SURPRISE_MESSAGE", // animated sticker message, using payload
  TEXT_MESSAGE = "TEXT_MESSAGE",
  TEXT_MESSAGE_WITH_LINK = "TEXT_MESSAGE_WITH_LINK",
  UNKNOWN = "UNKNOWN",
  UNSUPPORTED = "UNSUPPORTED",
  VIDEO_MESSAGE = "VIDEO_MESSAGE",
  VIDEO_PTT = "VIDEO_PTT",
  VIDEO_SAVED_INFO_MESSAGE = "VIDEO_SAVED_INFO_MESSAGE",
  VIP_ASSIGNMENT_MESSAGE = "VIP_ASSIGNMENT_MESSAGE",
  VOICE_MESSAGE_2 = "VOICE_MESSAGE_2",
}

export enum Role {
  ADMIN = 1,
  REGULAR,
}

export enum ConversationState {
  ACTIVE = "ACTIVE",
  CHAT_REQUEST = "CHAT_REQUEST",
}
export enum Mode {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export type CallLogPayload = {
  duration?: number;
  id?: string;
  is_incoming?: boolean;
  mode?: Mode;
};

export type ChatPrivacySettings = {
  conversationId?: string;
  saveMediaEnabled?: boolean;
  screenShotsEnabled?: boolean;
};

export type Property = {
  key: string;
  value: string;
};

export type GroupMember = {
  account_info: AccountInfo;
  role: Role;
};

export type Group = {
  creatorId?: string;
  familyId?: string;
  group_id: string;
  groupMembersCount?: number;
  left?: boolean; // if true, fields below are not populated
  members?: GroupMember[]; // includeGroupMembers == true
  muted?: boolean;
  name?: string;
  pictureUrl?: string;
  premiumMessageEnabled?: boolean;
};

export type ConversationInfo = {
  account_info?: AccountInfo; // for 1-on-1 chats only, includeAccountInfo == true
  chat_initiator?: boolean; // for 1-on-1 chats only
  conversation_id: string;
  group_info?: Group; // for group chats only, includeGroupInfo == true or includeGroupUpdates == true
  hidden?: boolean;
  last_message_ts?: number;
  last_read_message_ts?: number;
  last_self_read_message_ts?: number;
  last_update_ts?: number;
  properties?: Property[]; // "liveFamily"="1", "replyable"="1"
  state?: ConversationState;
  unread_message_count?: number;
};

export type MessageIdentifier = {
  chat_id?: string;
  id: number;
  ts: number;
};

export type MessageMedia = {
  creation_ts?: number;
  download_url?: string;
  duration?: number;
  height?: number;
  media_id?: string;
  size?: number;
  thumbnail_url?: string;
  type?: number;
  width?: number;
};

export type GroupMessagePayload = {
  joined_account_ids?: string[];
  kicked_by_account_id?: string;
  kicker_first_name?: string;
  kicker_last_name?: string;
};

export type SdkMessagePayload = {
  app_display_name?: string;
  app_logo_url?: string;
  payload?: string;
};

export type ForwardInfo = {
  from_account_id?: string;
  from_account_name?: string;
};

export type Message = {
  additional_payload?: string; // proto in base64
  alt?: string;
  body?: string;
  call_log_payload?: CallLogPayload;
  category_identifier?: string;
  edited?: boolean;
  expiration_date?: number;
  forward_info?: ForwardInfo;
  from?: string;
  group_msg_payload?: GroupMessagePayload;
  id: MessageIdentifier;
  liked_by_me?: boolean; // for 1-on-1 chat if not mine messages and likesCount>0 - then true, for group chats - need to store/calculate
  likes_count?: number;
  media?: MessageMedia[];
  // BI params:
  message_uuid?: string;
  payload?: string; // proto in base64 (TCSocialPost, TCProfileInfo, ChatGiftMessage)
  reply_message_id?: MessageIdentifier;
  rule_id?: string;
  sdk_message_payload?: SdkMessagePayload;
  tracking_id?: string;
  trigger_id?: string;
  type: MessageType;
};

export type Conversation = {
  conversation: ConversationInfo;
  has_more_messages?: boolean; // true if not all messages for given conditions/bulk size are returned
  messages?: Message[];
  peer_preferences?: ChatPrivacySettings;
};

export type ActionInfo = {
  actionText: string;
  actionUrl: string;
  installUrl: string;
};

export type GetMessageTranslationParams = {
  conversationId: string;
  locale: string;
  message: string;
  messageId: number;
  senderId?: string;
};

export type UnlockPremiumMessagePayload = {
  messageId: string;
};

export enum AvatarReferenceType {
  HEADER = "HEADER",
  NONE = "NONE",
}

export enum AvatarConversationType {
  MESSAGE_REQUEST = "MESSAGE_REQUEST",
  NONE = "NONE",
  SINGLE_CHAT = "SINGLE_CHAT",
}

export enum ChatMessageSentFlags {
  AUDIO = "audio",
  GIFT = "gift",
  IMAGE = "image",
  PROFILE = "profile",
  TEXT = "text",
  VIDEO = "video",
}

export type ChatMessageSentReason = Nullable<string>;

export enum ConversationScreenState {
  CHAT_REQUEST = "chat_request",
  ONE_ON_ONE_CHAT = "1on1_chat",
}

export enum SingleChatMessageSentComment {
  CHAT_REQUEST_ACCEPTED = "chat_request_accepted",
  CHAT_REQUEST_SENT = "chat_request_sent",
  EMPTY = "",
}

export type ChatMessageAnalyticsParams = {
  chatId: string;
  comment: SingleChatMessageSentComment | null;
  flags: ChatMessageSentFlags[];
  membersCnt: number;
  peerId?: string;
  recipientAccountId: string;
  screenState: ConversationScreenState;
};

export interface SendMediaData {
  analyticsParams: ChatMessageAnalyticsParams;
  conversationId: string;
  file: File;
}

export interface FetchMosV2ChatLineupResponse {
  items: MosItem[];
  lineupId: string;
}

export interface MosChatData {
  items: MosUiItem[];
  missedGiftsIds: GiftId[];
}
