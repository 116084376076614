import {
  linkToWebviewApmOffers,
  linkToWebviewCheckout,
  linkToWebviewPaymentButton,
  linkToWebviewSafechargeOffers,
  linkToWebviewSinglePaymentMethod,
} from "ui/navigation/links";

export const WEBVIEW_ENVIRONMENT_PROPERTY = "webview";

export const WEBVIEW_ONLY_ROUTES = [
  linkToWebviewApmOffers,
  linkToWebviewSafechargeOffers,
  linkToWebviewSinglePaymentMethod,
  linkToWebviewCheckout,
  linkToWebviewPaymentButton,
];

export const WEBVIEW_QUERY_PARAM = "webview";
