import React, { useRef, useState } from "react";
import classnames from "classnames";
import { MosContainerSkeleton } from "src/features/chat/components/Mos/MosContainerSkeleton";
import { MosList } from "src/features/chat/components/Mos/MosList";
import { useMosItemsForChat } from "src/features/chat/hooks/useMosItemsForChat";
import { CarouselButton } from "src/features/chat/imports/components";
import { Breakpoints } from "src/features/chat/imports/constants";
import { useBreakpointPrecise } from "src/features/chat/imports/hooks";
import styles from "src/features/chat/components/Mos/MosContainer.scss";

interface MosContainerProps {
  companionId: string;
}

const SCROLL_OFFSET = 300;

export const MosContainer = ({ companionId }: MosContainerProps) => {
  const { items, isLoading } = useMosItemsForChat({ companionId });

  const breakpoint = useBreakpointPrecise();
  const carouselRef = useRef<HTMLDivElement>(null);

  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  if (isLoading) {
    return <MosContainerSkeleton />;
  }

  if (!items?.length) {
    return null;
  }

  const handleScroll = (scrollOffset: number) => () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className={classnames(styles.mosContainer, styles[breakpoint])}>
      {isDesktop && (
        <CarouselButton
          onClick={handleScroll(-SCROLL_OFFSET)}
          className={classnames(styles.carouselBtn, styles.btnPrev)}
          disabled={isPrevBtnDisabled}
        />
      )}

      <MosList
        mosItems={items}
        carouselRef={carouselRef}
        setIsPrevBtnDisabled={setIsPrevBtnDisabled}
        setIsNextBtnDisabled={setIsNextBtnDisabled}
      />

      {isDesktop && (
        <CarouselButton
          type="next"
          onClick={handleScroll(SCROLL_OFFSET)}
          className={classnames(styles.carouselBtn, styles.btnNext)}
          disabled={isNextBtnDisabled}
        />
      )}
    </div>
  );
};
